import Vue from 'vue'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import Match from '~/models/Match'
import MatchService from '~/services/match-service'

// Interfaces and Types
interface MatchQueryParams {
    page?: number
    tab?: string
}

type Pagination = {
    count: number
    next: number | null
    previous: number | null
}

type Tab = {
    name: string
    title: string
    query: { tab: string }
    countField: string
}

type FieldValue = string | Array<any> | boolean | number | unknown

const extractPage = (url: string): number | null => {
    const urlObject = new URL(url)
    const query = new URLSearchParams(urlObject.search)
    const page = query.get('page')
    if (page === null) {
        return null
    }
    return parseInt(page)
}

@Module({
    name: 'matches',
    stateFactory: true,
    namespaced: true,
})
export default class MatchesModule extends VuexModule {
    items: Record<number, Match> = {}

    collections: Record<string, [number]> = {}

    paginations: Record<string, Pagination> = {}

    tabs: Tab[] = [
        {
            name: 'listing-matches-internal-check',
            title: 'INTERNAL CHECK',
            query: { tab: 'MATCH_INTERNAL_CHECK' },
            countField: 'matchInternalCheck',
        },
        {
            name: 'listing-matches-check-before-admission',
            title: 'CHECK BEFORE ADMIS.',
            query: { tab: 'MATCH_CHECK_BEFORE_ADMISSION' },
            countField: 'matchCheckBeforeAdmission',
        },
        {
            name: 'listing-matches-candidate-check',
            title: 'CAND. CHECK',
            query: { tab: 'MATCH_CANDIDATE_CHECK' },
            countField: 'matchCandidateCheck',
        },
        {
            name: 'listing-matches-interested-before-admission',
            title: 'INTERESTED BEFORE ADMIS.',
            query: { tab: 'MATCH_INTERESTED_BEFORE_ADMISSION' },
            countField: 'matchInterestedBeforeAdmission',
        },
        {
            name: 'listing-matches-client-check',
            title: 'CLIENT CHECK',
            query: { tab: 'MATCH_CLIENT_CHECK' },
            countField: 'matchClientCheck',
        },
        {
            name: 'listing-matches-interview-steps',
            title: 'INTERVIEW - STEPS',
            query: { tab: 'MATCH_INTERVIEW' },
            countField: 'matchInterview',
        },
        {
            name: 'listing-matches-offer',
            title: 'OFFER',
            query: { tab: 'MATCH_PRESENTED_OFFER' },
            countField: 'matchPresentedOffer',
        },
        {
            name: 'listing-matches-hired',
            title: 'HIRED',
            query: { tab: 'MATCH_HIRED' },
            countField: 'matchHired',
        },
        {
            name: 'listing-matches-refused',
            title: 'REFUSED',
            query: { tab: 'MATCH_REFUSED' },
            countField: 'matchRefused',
        },
    ]

    counts: Record<string, number> = {}

    get MatchById() {
        return (id: number): Match => {
            return this.items[id]
        }
    }

    get MatchesByIds() {
        return (ids: Array<number>): Array<Match> => {
            // I added filter here to avoid having undefined return in the array
            return ids.map((id) => this.items[id]).filter((item) => item)
        }
    }

    get Collection() {
        return (collectionName: string): Array<number> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName]
        }
    }

    get MatchesForCollection() {
        return (collectionName: string): Array<Match> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName].map((id) => this.items[id])
        }
    }

    get NextPageForCollection() {
        return (collectionName: string): number | null => {
            if (!this.paginations[collectionName]) {
                return null
            }
            return this.paginations[collectionName].next
        }
    }

    get HasMoreForCollection() {
        return (collectionName: string): boolean => {
            return !!this.paginations[collectionName]?.next
        }
    }

    get CountForCollection() {
        return (collectionName: string): number | null => {
            if (!this.paginations[collectionName]) {
                return null
            }
            return this.paginations[collectionName].count
        }
    }

    get isMatchCreatedByCandidate() {
        return (match: Match): boolean => {
            return match.creatorType === 'Ca'
        }
    }

    get Tabs() {
        return this.tabs
    }

    get CollectionName() {
        return (currentTabQuery: string): string => {
            return `matchesForTab${currentTabQuery}`
        }
    }

    get CurrentTab() {
        return (routeName: string): Tab | undefined => {
            return this.tabs.find((tab) => routeName.endsWith(tab.name))
        }
    }

    @Mutation
    setItems({ matches }: { matches: Array<Match> }) {
        matches.forEach((item: Match) => {
            Vue.set(this.items, item.id, Match.fromObject(item))
        })
    }

    @Mutation
    setCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        Vue.set(this.collections, collectionName, ids)
    }

    @Mutation
    appendToCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        const allIds = (this.collections[collectionName] || []).concat(ids)
        const uniqIds = [...new Set(allIds)]
        Vue.set(this.collections, collectionName, uniqIds)
    }

    @Mutation
    setPagination({ pagination, collectionName }: { pagination: Pagination; collectionName: string }): void {
        Vue.set(this.paginations, collectionName, pagination)
    }

    @Mutation
    setCollectionCount({ collectionName, length }: { collectionName: string; length: number }): void {
        this.paginations[collectionName].count -= length
    }

    @Mutation
    removeFromCollection({ item, collectionName }: { item: number; collectionName: string }): void {
        const index = this.collections[collectionName].indexOf(item)
        Vue.delete(this.collections[collectionName], index)
    }

    @Mutation
    toggleFromCollection({ id, collectionName }: { id: number; collectionName: string }) {
        if (this.collections[collectionName]) {
            const index = this.collections[collectionName].indexOf(id)
            if (index >= 0) {
                Vue.delete(this.collections[collectionName], index)
            } else {
                this.collections[collectionName].push(id)
            }
        } else {
            Vue.set(this.collections, collectionName, [id])
        }
    }

    @Mutation
    deleteCollection(collectionName: string) {
        Vue.delete(this.collections, collectionName)
    }

    @Mutation
    setCounts(counts: Record<string, number>): void {
        this.counts = counts
    }

    @Action({ rawError: true })
    async FETCH_MATCHES({ collectionName, params }: { collectionName: string; params: any }): Promise<any> {
        try {
            const response = await MatchService.getMatches(params)
            const ids: Array<number> = response.results.map((item: Match) => item.id)
            const next = response.next === null ? null : extractPage(response.next)
            const previous = response.previous === null ? null : extractPage(response.previous)
            const count = response.count

            this.setItems({ matches: response.results })
            this.setCollection({ ids, collectionName })
            this.setPagination({
                collectionName,
                pagination: {
                    next,
                    previous,
                    count,
                },
            })
        } catch (error: any) {
            throw error.response || error
        }
    }

    @Action({ rawError: true })
    async PATCH_MATCH(params: { id: number; fields: Record<string, FieldValue> }): Promise<any> {
        try {
            const match = await MatchService.patchMatch(params.id, params.fields)
            this.setItems({ matches: [match] })
            return match
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async PATCH_NEXT_STEP(params: { id: number }): Promise<any> {
        try {
            await MatchService.patchNextStep(params.id)
            this.setItems({
                matches: [
                    {
                        ...this.items[params.id],
                        currentStep: this.items[params.id].currentStep + 1,
                        currentStepDate: null,
                    },
                ],
            })
        } catch (error: any) {
            throw error.response || error
        }
    }

    @Action({ rawError: true })
    async PATCH_PREVIOUS_STEP(params: { id: number }): Promise<any> {
        try {
            const previousMatch = await MatchService.patchPreviousStep(params.id)
            this.setItems({
                matches: [
                    {
                        ...this.items[params.id],
                        currentStep: this.items[params.id].currentStep - 1,
                        currentStepDate: previousMatch.currentStepDate,
                    },
                ],
            })
        } catch (error: any) {
            throw error.response || error
        }
    }

    @Action({ rawError: true })
    async PATCH_CURRENT_STEP_DATE(params: { id: number; date: Date | null }): Promise<void> {
        try {
            await MatchService.patchCurrentStepDate(params.id, params.date)
            const newMatch = { ...this.items[params.id], currentStepDate: params.date }
            this.setItems({ matches: [newMatch] })
        } catch (error: any) {
            throw error.response || error
        }
    }

    @Action({ rawError: true })
    async REFRESH_LISTING({
        routeQuery,
        tabQuery,
        collectionName,
    }: {
        routeQuery: MatchQueryParams
        tabQuery: MatchQueryParams
        collectionName: string
    }): Promise<void> {
        try {
            const counts = await MatchService.getMatchesCount({ ...routeQuery })
            await this.context.dispatch('FETCH_MATCHES', {
                collectionName,
                params: { ...routeQuery, ...tabQuery },
            })
            this.context.commit('setCounts', counts)
        } catch (error: any) {
            throw error.response || error
        }
    }
}
