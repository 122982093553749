//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        itemType: {
            type: String,
            required: false,
            default: 'Candidates',
        },
    },
}
