import Vue from 'vue'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import Job from '~/models/Job'
import JobService from '~/services/job-service'
import ListingService from '~/services/listing-service'
type FieldValue = string | Array<any> | boolean | number | unknown

@Module({
    name: 'jobs',
    stateFactory: true,
    namespaced: true,
})
export default class JobsModule extends VuexModule {
    /* Items is a dictionnary with id as key and Job as value
        Example : {
            1: Job,
            23: Job
        }
    */
    items: Record<number, Job> = {}

    /*
        Collections is a dictionnary with string as key and an array of ids as value
        The numbers in those values are pointing to the keys store in the items
        Example : {
            jobsForCompany1: [number, number, number],
        }
    */
    collections: Record<string, [number]> = {}
    myJobsCount = 0
    allJobsCount = 0
    currentJobIdToDisplay = 0

    // Getters
    get JobsByIds() {
        return (ids: Array<number>): Array<Job> => {
            // I added filter here to avoid having undefined return in the array
            return ids.map((id) => this.items[id]).filter((item) => item)
        }
    }

    get JobById() {
        return (id: number): Job => {
            return this.items[id]
        }
    }

    get MyJobsCount() {
        return this.myJobsCount || '...'
    }

    get AllJobsCount() {
        return this.allJobsCount || '...'
    }

    get JobsForCompanyId() {
        return (companyId: string): Array<Job> => {
            if (!this.collections[`jobsForCompany${companyId}`]) {
                return []
            }
            // I added filter here to avoid having undefined return in the array
            return this.collections[`jobsForCompany${companyId}`].map((id) => this.items[id]).filter((item) => item)
        }
    }

    get JobsForCurrentUser() {
        if (!this.collections.jobsForCurrentUser) {
            return []
        }
        return this.collections.jobsForCurrentUser.map((id) => this.items[id]).filter((item) => item)
    }

    get JobsForCurrentUserWithFits() {
        if (!this.collections.jobsForCurrentUserWithFits) {
            return []
        }
        return this.collections.jobsForCurrentUserWithFits.map((id) => this.items[id]).filter((item) => item)
    }

    get AllJobs() {
        if (!this.collections.allJobs) {
            return []
        }
        return this.collections.allJobs.map((id) => this.items[id]).filter((item) => item)
    }

    get CurrentJobToDisplay() {
        return this.items[this.currentJobIdToDisplay]
    }

    get CandidatesFromJobFits() {
        return (id: number) => {
            return this.items[id]?.fits.map((fit: any) => fit.candidate)
        }
    }

    // Mutations
    @Mutation
    setCollection(params: { jobs: Array<Job>; collectionName: string }) {
        params.jobs.map((item) => {
            Vue.set(this.items, item.id, item)
        })
        const jobIds: Array<number> = params.jobs.map((item) => item.id)
        Vue.set(this.collections, params.collectionName, jobIds)
    }

    @Mutation
    setRefusedFitsCount({ id, length }: { id: number; length: number }) {
        this.items[id].refusedFitsCount += length
    }

    @Mutation
    resetRefusedFitsCount(id: number) {
        this.items[id].refusedFitsCount = 0
    }

    @Mutation
    fetchCompanyJobSuccess(job: Job): void {
        if (job.company) {
            Vue.set(this.collections, `jobsForCompany${job.company}`, [job.id])
        }
    }

    @Mutation
    setCurrentUserJobsCount(count: number): void {
        this.myJobsCount = count
    }

    @Mutation
    setAllJobsCount(count: number): void {
        this.allJobsCount = count
    }

    @Mutation
    setItem(job: Job) {
        // We don't overide the fits field in a job object
        Vue.set(this.items, job.id, { ...job, fits: job.fits?.length > 1 ? job.fits : this.items[job.id]?.fits })
    }

    @Mutation
    setCurrentJobToDisplay(jobId: number) {
        this.currentJobIdToDisplay = jobId
    }

    @Mutation
    setMatchesForJob(payload: { jobId: number; matches: any[] }): void {
        if (this.items[payload.jobId]) {
            this.items[payload.jobId].matches = payload.matches
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_JOB(jobId: string): Promise<any> {
        try {
            const job = await JobService.fetchJob(jobId)
            this.setItem(job)
            this.fetchCompanyJobSuccess(job)
            return job
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_CURRENT_USER_JOBS(data: any): Promise<any> {
        try {
            const list = await ListingService.getMyJobsListing(data)
            this.setCurrentUserJobsCount(list.count)
            this.setCollection({ jobs: list.results, collectionName: 'jobsForCurrentUser' })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_ALL_JOBS(companyId: string): Promise<any> {
        try {
            const jobs = await JobService.fetchAllJobs(companyId)
            this.setCollection({ jobs, collectionName: `jobsForCompany${companyId}` })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_ALL_JOBS_LISTING(data: any): Promise<any> {
        try {
            const list = await ListingService.getAllJobsListing(data)
            this.setAllJobsCount(list.count)
            this.setCollection({ jobs: list.results, collectionName: 'allJobs' })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async PATCH_JOB(params: { id: number; field: string; value: FieldValue }): Promise<Job> {
        try {
            const job = await JobService.patchJob(params.id, params.field, params.value)
            this.setItem(job)
            return job
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async MASS_PATCH_JOB(params: { id: number; fields: Record<string, FieldValue> }): Promise<Job> {
        try {
            const job = await JobService.massPatchJob(params.id, params.fields)
            this.setItem(job)
            return job
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_ACTIVE_JOBS(companyId: string): Promise<any> {
        try {
            const jobs = await JobService.fetchActiveJobs(companyId)
            this.setCollection({ jobs, collectionName: `jobsForCompany${companyId}` })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_CURRENT_USER_JOBS_WITH_FITS(): Promise<any> {
        try {
            const jobs = await JobService.getMyJobsWithFits()
            this.setCollection({ jobs, collectionName: 'jobsForCurrentUserWithFits' })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async LAUNCH_AUTO_SEARCH(jobId: number) {
        try {
            await JobService.launchAutoSearch(jobId)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async TOGGLE_IS_PUBLIC_FOR_CLIENT_AND_REFRESH_MATCHES(payload: {
        id: number
        isPublicForClient: boolean
    }): Promise<void> {
        try {
            const { id, isPublicForClient } = payload
            // Toggle the is_public_for_client field
            const updatedJob = await this.PATCH_JOB({
                id,
                field: 'is_public_for_client',
                value: isPublicForClient,
            })
            // Check if toggling was successful and then fetch matches
            if (updatedJob) {
                const matches = await JobService.fetchMatchesForJob(id)
                // Store the refreshed matches in the state.
                this.setMatchesForJob({ jobId: id, matches })
                this.context.commit('matches/setItems', { matches }, { root: true })
            }
        } catch (error: any) {
            throw error.response
        }
    }
}
