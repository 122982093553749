import { componentsStore } from '~/utils/store-accessor'

/* eslint-disable camelcase */
export class Prioritised {
    public constructor(public readonly id: number, public priority: number = 1) {}

    static getComponentsName(id: number, component: string): string {
        // getting the component name from the component store
        return componentsStore.componentNameById(id, component)
    }
}
