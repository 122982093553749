export default class TeamUser {
    public constructor(
        public readonly id: number,
        public readonly contactId: number,
        public readonly fullName: string = '',
        public email: string = '',
        public pictureUrl: string = '',
        public team: string = ''
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(teamUser: any): TeamUser {
        return Object.assign(new TeamUser(teamUser.id, teamUser.contact_id), teamUser)
    }
}
