export class Toaster {
    public constructor(
        public color: string = '',
        public title: string = '',
        public body: string = '',
        public emoji: string = '',
        public link: string = '',
        public top: boolean = false,
        public right: boolean = false,
        public bottom: boolean = false,
        public left: boolean = false,
        public timeout: number = 3000
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(object: any): Toaster {
        return Object.assign(new Toaster(), object)
    }
}
