/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { client } from '../plugins/axios-plugin'
type NewMatch = {
    candidate: number
    job: number
    messageToClient: string
    currentStatusKey: string
    sendNotification: boolean
}

type Match = {
    id: number
    currentStatusString: string
}

type MatchPreview = {
    candidateNoGo: string
    jobProfileLookedFor: string
    match: Match
}

type statusAction = 'success' | 'fail'
type statusKey =
    | 'MATCH_PROPOSITION_PRESELECTION'
    | 'MATCH_CANDIDATE_CHECK'
    | 'MATCH_INTERVIEW'
    | 'MATCH_PRESENTED_OFFER'

type FieldValue = string | Array<any> | boolean | number | unknown

export default class MatchService {
    static async fetchMatchPreview(candidateId: number, jobId: number): Promise<MatchPreview> {
        const response = await client.get(`/matches/preview/`, { params: { candidate_id: candidateId, job_id: jobId } })
        return response.data
    }

    static timeout() {
        return new Promise((resolve) => setTimeout(resolve, 500))
    }

    static async createMatch(newMatch: NewMatch) {
        const status = newMatch.currentStatusKey
        const response = await client.post(`matches/?status=${status}`, newMatch)
        return response.data
    }

    static async getMatches(params: any): Promise<any> {
        const response = await client.get(`/matches/`, {
            params,
        })
        return response.data
    }

    static async getMatchesCount(params: any): Promise<any> {
        const response = await client.get(`/matches/counts`, { params })
        return response.data
    }

    static async patchNextStatus(id: number, action: statusAction, data: any): Promise<any> {
        const response = await client.patch(`/matches/${id}/next_status/`, {
            action,
            ...data,
        })
        return response.data
    }

    static async patchMatch(id: number, fields: Record<string, FieldValue>): Promise<any> {
        const response = await client.patch(`/matches/${id}/`, fields)
        return response.data
    }

    static async patchNextStep(id: number): Promise<any> {
        const response = await client.patch(`/matches/${id}/next_step/`)
        return response.data
    }

    static async patchPreviousStep(id: number): Promise<any> {
        const response = await client.patch(`/matches/${id}/previous_step/`)
        return response.data
    }

    static async patchCurrentStepDate(id: number, date: Date | null): Promise<any> {
        const response = await client.patch(`/matches/${id}/current_step/`, {
            dateMeeting: date ? date.toISOString() : null,
        })
        return response.data
    }

    static async postFollowupSequence(id: number): Promise<any> {
        const response = await client.post(`/matches/${id}/send_followup_sequence/`)
        return response.data
    }

    static async patchToStatus(id: number, statusKey: statusKey): Promise<any> {
        const response = await client.patch(`/matches/${id}/to_status/`, {
            status: statusKey,
        })
        return response.data
    }

    static async postHides(candidateId: number, companyIds: Array<number>): Promise<any> {
        const response = await client.post(`/matches/hides/`, {
            candidate: candidateId,
            companies: companyIds,
        })
        return response.data
    }

    static async getMatchStatuses(matchId: number): Promise<string> {
        const response = await client.get(`/matches/${matchId}/statuses`)
        return response?.data?.results
    }

    static async getMatchRounds(matchId: number): Promise<any> {
        const response = await client.get(`/matches/${matchId}/rounds`)
        return response?.data?.results
    }
}
