import Vue from 'vue'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import TeamUser from '@/models/TeamUser'
import TeamUserService from '@/services/team-user-service'

@Module({
    name: 'teamUsers',
    stateFactory: true,
    namespaced: true,
})
export default class TeamUsersModule extends VuexModule {
    items: Record<number, TeamUser> = {}

    // Getters
    get AllTeamUsers() {
        return (): Array<TeamUser> => {
            return Object.values(this.items)
        }
    }

    get AllCoaches() {
        return (): Array<TeamUser> => {
            return Object.values(this.items).filter((user) => user.team === 'Coach' || user.team === 'L33T')
        }
    }

    get TeamUserById() {
        return (id: number): TeamUser => {
            return this.items[id]
        }
    }

    // Mutations
    @Mutation
    fetchTeamUsersSuccess(items: Array<TeamUser>): void {
        items.forEach((item) => {
            Vue.set(this.items, item.id, item)
        })
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_TEAM_USERS(): Promise<any> {
        try {
            const teamUsers = await TeamUserService.getTeamUsers()
            this.fetchTeamUsersSuccess(teamUsers)
        } catch (error: any) {
            throw error.response
        }
    }
}
