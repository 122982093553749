/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { ListingFilter } from '~/models/ListingFilter'

@Module({
    name: 'listingFilters',
    stateFactory: true,
    namespaced: true,
})
export default class ToasterModule extends VuexModule {
    // State
    listingFilters: any = null as any

    // Mutations
    @Mutation
    addListingFilter(listingFilter: ListingFilter): any {
        const value = listingFilter.value.join(',')
        if ((this.listingFilters || {})[listingFilter.field]) {
            if (value) {
                this.listingFilters[listingFilter.field] = value
            } else {
                delete this.listingFilters[listingFilter.field]
            }
        } else {
            this.listingFilters = {
                ...this.listingFilters,
                [listingFilter.field]: value,
            }
        }
    }

    @Mutation
    resetFilters(): void {
        this.listingFilters = null
    }

    // Actions
    @Action({ rawError: true })
    async ADD_LISTING_FILTER(listingFilter: ListingFilter) {
        await this.addListingFilter(listingFilter)
    }

    @Action({ rawError: true })
    RESET_FILTERS() {
        this.resetFilters()
    }

    // Getters
    get getListingFilters(): any {
        return this.listingFilters
    }
}
