/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

type candidateMainView = 'CV' | 'experience'

@Module({
    name: 'ui',
    stateFactory: true,
    namespaced: true,
})
export default class AppModule extends VuexModule {
    // State
    candidateMainView: candidateMainView = 'CV'
    isMatchingPanelOpened = false
    matchingPanelProps = {}
    isMatchingMultiplePanelOpened = false
    matchingMultiplePanelProps = {}
    isCandidateDrawerOpened = false

    // Actions
    @Action({ rawError: true })
    async SET_CANDIDATE_MAIN_VIEW({ mainView }: { mainView: candidateMainView }) {
        await this.setCandidateMainView({ mainView })
    }

    @Action({ rawError: true })
    // { fits, collectionName }: { fits: Array<Fit>; collectionName: string }
    TOGGLE_MATCHING_PANEL({
        isOpened,
        from,
        jobId,
        candidateId,
        collectionName,
        fit,
    }: {
        isOpened: boolean
        from: string
        jobId: number
        candidateId: number
        collectionName: string
        fit: number
    }) {
        this.setMatchingPanel(isOpened)
        this.setMatchingPanelProps({ jobId, candidateId, from, collectionName, fit })
    }

    @Action({ rawError: true })
    TOGGLE_MATCHING_MULTIPLE_PANEL({
        isOpened,
        from,
        collectionName,
    }: {
        isOpened: boolean
        from: string
        collectionName: string
    }) {
        this.setMatchingMultiplePanel(isOpened)
        this.setMatchingMultiplePanelProps({ from, collectionName })
    }

    // Mutations
    @Mutation
    setCandidateMainView({ mainView }: { mainView: candidateMainView }) {
        this.candidateMainView = mainView
    }

    @Mutation
    setMatchingPanel(isOpened: boolean) {
        this.isMatchingPanelOpened = isOpened
    }

    @Mutation
    setMatchingMultiplePanel(isOpened: boolean) {
        this.isMatchingMultiplePanelOpened = isOpened
    }

    @Mutation
    setCandidateDrawer(isOpened: boolean) {
        this.isCandidateDrawerOpened = isOpened
    }

    @Mutation
    setMatchingPanelProps({
        jobId,
        candidateId,
        from,
        collectionName,
        fit,
    }: {
        jobId: number
        candidateId: number
        from: string
        collectionName: string
        fit: number
    }) {
        this.matchingPanelProps = { jobId, candidateId, from, collectionName, fit }
    }

    @Mutation
    setMatchingMultiplePanelProps({ from, collectionName }: { from: string; collectionName: string }) {
        this.matchingMultiplePanelProps = { from, collectionName }
    }

    // Getters
    get CandidateMainView(): candidateMainView {
        return this.candidateMainView
    }

    get IsMatchingPanelOpened(): boolean {
        return this.isMatchingPanelOpened
    }

    get IsMatchingMultiplePanelOpened(): boolean {
        return this.isMatchingMultiplePanelOpened
    }

    get IsCandidateDrawerOpened(): boolean {
        return this.isCandidateDrawerOpened
    }

    get MatchingPanelProps(): any {
        return this.matchingPanelProps
    }

    get MatchingMultiplePanelProps(): any {
        return this.matchingMultiplePanelProps
    }
}
