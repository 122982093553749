import { ComponentElement } from '@/models/ComponentElement'

// This constructor creates a dictionnary of the different components
// It also creates Component instances
export class Components {
    [key: string]: Array<ComponentElement>

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    public constructor(payload: any) {
        // the payload here is a dictionnary where each key is an array of Component instances
        for (const key in payload) {
            if (payload[key]) {
                this[key] = Components.createComponentInstances(payload[key])
            }
        }
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static createComponentInstances(data: any): ComponentElement[] {
        return data.reduce((array: Array<ComponentElement>, value: any) => {
            array.push(ComponentElement.fromObject(value))
            return array
        }, [])
    }
}
