//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        filtersName: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            menuMin: false,
            menuMax: false,
            values: {
                min: '',
                max: '',
            },
        }
    },
    watch: {
        'values.min'(newVal, oldVal) {
            this.updateFilters()
        },
        'values.max'(newVal, oldVal) {
            this.updateFilters()
        },
    },
    created() {
        const filter = this.$store.getters['search/GetFilters'](this.filtersName)
        if (filter && filter.conditions) {
            const minCondition = filter.conditions.find((c) => c.operator === '>=')
            const maxCondition = filter.conditions.find((c) => c.operator === '<=')
            if (minCondition) this.values.min = minCondition.value
            if (maxCondition) this.values.max = maxCondition.value
        }
    },
    methods: {
        updateFilters() {
            const conditions = []
            if (this.values.min) {
                conditions.push({ operator: '>=', value: this.values.min })
            }
            if (this.values.max) {
                conditions.push({ operator: '<=', value: this.values.max })
            }
            this.$store.commit('search/setFilters', {
                name: this.filtersName,
                filter: { conditions },
            })
        },
    },
}
