//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        filtersName: {
            type: String,
            required: true,
        },
        facetsName: {
            type: String,
            required: false,
            default: '',
        },
    },
    computed: {
        filters: {
            get() {
                return (this.$store.getters['search/GetFilters'](this.filtersName) || {}).value || []
            },
            set(value) {
                this.$store.commit('search/setFilters', {
                    name: this.filtersName,
                    filter: { operator: 'OR', value },
                })
            },
        },
        aggregations: {
            get() {
                let values = []
                if (this.facetsName) {
                    const facets = this.$store.getters['search/GetFacets'](this.facetsName)
                    if (facets) {
                        for (const [value, text] of Object.entries(facets)) {
                            values.push({ text, value })
                        }
                    }
                    values = values.sort((a, b) => {
                        return a.text.localeCompare(b.text)
                    })
                }
                return values
            },
        },
    },
}
