/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { Toaster } from '~/models/Toaster'

@Module({
    name: 'toasters',
    stateFactory: true,
    namespaced: true,
})
export default class ToasterModule extends VuexModule {
    // State
    toaster: Toaster = null as any

    // Mutations
    @Mutation
    setToaster(toaster: Toaster): any {
        this.toaster = toaster
    }

    @Mutation
    removeToaster(): any {
        this.toaster = null as any
    }

    // Actions
    @Action({ rawError: true })
    async SET_TOASTER(toaster: any) {
        await this.setToaster(Toaster.fromObject(toaster))
    }

    @Action({ rawError: true })
    async SET_ERROR_TOASTER(error: any) {
        let errorMessage = error?.data?.detail || 'Une erreur est survenue'
        if (error?.response?.data?.error) {
            errorMessage = error.response.data.error
        }
        const toaster = {
            color: 'var(--error)',
            title: errorMessage,
            emoji: '❌',
            top: true,
            right: true,
            timeout: 3000,
        }
        await this.setToaster(Toaster.fromObject(toaster))
    }

    @Action
    REMOVE_TOASTER() {
        this.removeToaster()
    }

    // Getters
    get Toaster(): Toaster {
        return this.toaster
    }
}
