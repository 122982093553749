import { Contact } from '../models/Contact'
import { client } from '../plugins/axios-plugin'

export default class CompanyContactService {
    static async getContacts(id: number): Promise<Contact[]> {
        const response = await client.get(`/companies/${id}/contacts/`)
        const contacts = response?.data
        return contacts?.map((contact: any) => Contact.fromObject(contact))
    }

    static async fetchContact(id: number, contactId: number): Promise<any> {
        const response = await client.get(`/companies/${id}/contacts/${contactId}/`)
        return response.data
    }

    static async getContact(id: number, contactId: number): Promise<Contact> {
        const contact = await this.fetchContact(id, contactId)
        return Contact.fromObject(contact)
    }

    static async sendAccessTrigger(companyId: number, contactId: number, triggerId: number): Promise<void> {
        await client.post(`/companies/${companyId}/contacts/${contactId}/send_access/`, { trigger: triggerId })
    }

    static async hijack(contactId: number): Promise<string> {
        const response = await client.get(`/users/log-as/?user=${contactId}`)
        return response?.data
    }
}
