//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash.debounce'
import TeamUserService from '~/services/team-user-service'
import InputPhoneNumber from '~/components/inputs/InputPhoneNumber'

export default {
    components: {
        InputPhoneNumber,
    },
    data() {
        return {
            genders: [
                {
                    text: 'Female',
                    value: 'F',
                },
                {
                    text: 'Male',
                    value: 'M',
                },
            ],
            localModel: {},
            debouncedFn: null,
            displayText: false,
        }
    },
    computed: {
        user() {
            return this.$auth?.user || {}
        },
        firstName() {
            return this.user.first_name
        },
        lastName() {
            return this.user.last_name
        },
        email() {
            return this.user.email
        },
        teams() {
            return this.$store.getters['components/componentsbyName']('teams')
        },
        phone() {
            return this.user.phone
        },
    },
    mounted() {
        this.localModel = this.user
        this.debouncedFn = debounce(this.setField, 500)
    },
    methods: {
        logout() {
            this.$auth.logout()
        },
        setField(field) {
            TeamUserService.patchMeUserField(this.localModel, field)
        },
        type(field) {
            this.debouncedFn(field)
        },
        typePhone(newPhoneNumber) {
            this.localModel.phone = newPhoneNumber
            this.debouncedFn('phone')
        },
        copyToClipboard() {
            const textToCopy = document.getElementById('text-to-copy').innerHTML
            navigator.clipboard.writeText(textToCopy)

            this.displayText = true
            setTimeout(() => {
                this.displayText = false
            }, 3000)
        },
    },
}
