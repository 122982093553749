import { client } from '../plugins/axios-plugin'
import { Contact } from '../models/Contact'

export default class ContactService {
    static async fetchContact(contactId: number): Promise<any> {
        const response = await client.get(`/contacts/${contactId}/`)
        return response.data
    }

    static async getContact(contactId: number): Promise<Contact> {
        const contact = await this.fetchContact(contactId)
        return Contact.fromObject(contact)
    }

    static async patchContact(contact: Contact): Promise<any> {
        const response = await client.patch(`/contacts/${contact.id}/`, contact)
        return response?.data
    }

    static async setContact(contact: Contact): Promise<Contact> {
        const patchedContact = await this.patchContact(contact)
        return Contact.fromObject(patchedContact)
    }

    static async patchContactUser(user: number, payload: Record<string, unknown>): Promise<any> {
        await client.patch(`/users/${user}/`, payload)
    }
}
