import { Prioritised } from '~/models/index'

/* eslint-disable camelcase */
export class PrioritisedCity extends Prioritised {
    public readonly id: number
    public readonly priority: number
    public readonly city: number
    public readonly cityName: string
    public readonly candidate: number
    constructor(id: number, city: number, cityName: string, priority: number, candidate: number) {
        super(id, priority)
        this.city = city
        this.cityName = cityName
        this.candidate = candidate
    }

    static toPrioritisedCity(prioritisedCity: PrioritisedCity): PrioritisedCity {
        const cityName = Prioritised.getComponentsName(prioritisedCity.city, 'citiesWanted')
        return new PrioritisedCity(
            prioritisedCity.id,
            prioritisedCity.city,
            cityName,
            prioritisedCity.priority,
            prioritisedCity.candidate
        )
    }
}
