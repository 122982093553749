//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        filtersName: {
            type: String,
            required: true,
        },
        facetsName: {
            type: String,
            required: false,
            default: '',
        },
    },
    data() {
        return {
            jobCategories: [],
        }
    },
    computed: {
        filters: {
            get() {
                return (this.$store.getters['search/GetFilters'](this.filtersName) || {}).value || []
            },
            set(value) {
                this.$store.commit('search/setFilters', {
                    name: this.filtersName,
                    filter: { operator: 'OR', value },
                })
            },
        },
        jobChoices() {
            const ids = this.jobCategories.map((category) => category.id)
            return this.$store.getters['components/jobsGroupedByCategories'](ids)
        },
    },
}
