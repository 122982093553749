/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { client } from '../plugins/axios-plugin'
import { FacetsResults, SearchQuery, SearchResults, SearchFilter } from '~/models/Search'

export default class SearchService {
    static async fetchCandidates(query: string): Promise<any> {
        const response = await client.get(`/contacts/candidates-search/?query=${encodeURIComponent(query)}`)
        return response.data?.results
    }

    // Here we create an instance of Components
    static async getCandidates(query: string): Promise<any> {
        return await this.fetchCandidates(query)
    }

    static async fetchCompanies(query: string): Promise<any> {
        const response = await client.get(`/companies/search/?query=${encodeURIComponent(query)}`)
        return response.data?.results
    }

    static async getCompanies(query: string): Promise<any> {
        return await this.fetchCompanies(query)
    }

    static async doSearch(searchQuery: SearchQuery): Promise<SearchResults> {
        const response = await client.post(`/searches/do/`, searchQuery)
        return SearchResults.fromObject(response.data)
    }

    static async fetchFacets(): Promise<any> {
        const response = await client.get(`/searches/facets/`)
        return response.data
    }

    static async getFacets(): Promise<FacetsResults> {
        const facetsResults = await this.fetchFacets()
        return FacetsResults.fromObject(facetsResults)
    }

    static async fetchSearches(): Promise<any> {
        const response = await client.get(`/searches/`)
        return response.data
    }

    static async getSearches(): Promise<any> {
        return await this.fetchSearches()
    }

    static async saveSearch(name: string, terms: string, filters: Record<string, SearchFilter>): Promise<any> {
        const response = await client.post(`/searches/`, { name, terms, filters })
        return response.data
    }

    static async deleteSearch(id: number): Promise<any> {
        const response = await client.delete(`/searches/${id}`)
        return response.data
    }
}
