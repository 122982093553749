import axios from 'axios'
import applyCaseMiddleware from 'axios-case-converter'

// Preserved keys are keys that will not be converted by the plugin
// with_ is used as a param in the matches listing
// x-amz and Content-Type are for aws file upload
const options = {
    preservedKeys: (input) => {
        if (input.startsWith('x-amz')) {
            return true
        }
        return ['with_', 'Content-Type'].includes(input)
    },
}

export const client = applyCaseMiddleware(
    axios.create({
        baseURL: process.env.baseUrl,
    }),
    options
)

export default function ({ $auth }) {
    client.interceptors.request.use(async (request) => {
        const token = $auth.strategy.token

        if ($auth.loggedIn && token.status().expired()) {
            await $auth.refreshTokens()
            token.sync()
        }

        request.headers.Authorization = token.get()
        return request
    })
}
