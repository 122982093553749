import TeamUser from '@/models/TeamUser'
import { client } from '../plugins/axios-plugin'

export default class TeamUserService {
    static async getTeamUsers(): Promise<TeamUser[]> {
        const response = await client.get(`/users/team-users/`)
        return response.data.map((teamUser: any) => TeamUser.fromObject(teamUser))
    }

    static patchMeUserField(me: TeamUser, field: keyof TeamUser & string): void {
        client.patch(`/users/me/`, { [field]: me[field] })
    }
}
