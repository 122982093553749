/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Vue from 'vue'

import { Referral } from '~/models/Referral'
import ReferralService from '~/services/referral-service'

@Module({
    name: 'referrals',
    stateFactory: true,
    namespaced: true,
})
export default class ReferralModule extends VuexModule {
    // State
    items: Record<number, Referral> = {}
    collections: Record<string, [number]> = {}

    // Getters
    get ReferralsForCollection() {
        return (collectionName: string): Array<Referral> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName].map((id) => this.items[id])
        }
    }

    get Collections() {
        return this.collections
    }

    // Mutations
    @Mutation
    setItem(referral: Referral): void {
        Vue.set(this.items, referral.id, Referral.fromObject(referral))
    }

    // Mutations
    @Mutation
    setItems(referrals: Array<Referral>): void {
        referrals.forEach((item: Referral) => {
            Vue.set(this.items, item.id, Referral.fromObject(item))
        })
    }

    @Mutation
    setCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        Vue.set(this.collections, collectionName, ids)
    }

    @Mutation
    appendToCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        const allIds = (this.collections[collectionName] || []).concat(ids)
        const uniqIds = [...new Set(allIds)]
        Vue.set(this.collections, collectionName, uniqIds)
    }

    @Mutation
    removeFromCollection({ item, collectionName }: { item: number; collectionName: string }): void {
        const index = this.collections[collectionName].indexOf(item)
        Vue.delete(this.collections[collectionName], index)
    }

    @Action({ rawError: true })
    async FETCH_REFERRALS({
        candidateId,
        collectionName,
    }: {
        candidateId: number
        collectionName: string
    }): Promise<any> {
        try {
            const response = await ReferralService.fetchReferrals(candidateId)
            const ids: Array<number> = response.results.map((item: Referral) => item.id)
            this.setItems(response.results)
            this.setCollection({ ids, collectionName })
            this.appendToCollection({ ids, collectionName })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async ADD_REFERRAL({ candidateId, collectionName }: { candidateId: number; collectionName: string }): Promise<any> {
        try {
            const newReferral = await ReferralService.addReferral(candidateId)
            const ids: Array<number> = [newReferral.id]
            this.setItem(newReferral)
            this.appendToCollection({ ids, collectionName })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async EDIT_REFERRAL({ referral, field }: { referral: Referral; field: keyof Referral & string }): Promise<any> {
        try {
            const newReferral = await ReferralService.editReferral(referral, field)
            this.setItem(newReferral)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async DELETE_REFERRAL({
        referralId,
        candidateId,
        collectionName,
    }: {
        referralId: number
        candidateId: number
        collectionName: string
    }): Promise<any> {
        try {
            await ReferralService.deleteReferral(referralId, candidateId)
            this.removeFromCollection({ item: referralId, collectionName })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async SET_REFERRAL_STATUS(patchingObject: any): Promise<any> {
        try {
            const referralResponse = await ReferralService.setReferralStatus(
                patchingObject.id,
                patchingObject.candidateId,
                patchingObject.action,
                patchingObject.sequence
            )
            this.setItem(referralResponse)
        } catch (error: any) {
            throw error.response
        }
    }
}
