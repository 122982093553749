/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { Contact } from '~/models/Contact'
import ContactService from '~/services/contact-service'

@Module({
    name: 'contacts',
    stateFactory: true,
    namespaced: true,
})
export default class ContactModule extends VuexModule {
    // State
    contact: Contact = null as any

    // Mutations
    @Mutation
    setContact(contact: Contact): any {
        this.contact = contact
    }

    // Actions
    @Action({ rawError: true })
    async GET_CONTACT(contactId: number) {
        if (this.Contact?.id !== contactId) {
            try {
                const contact = await ContactService.getContact(contactId)
                this.setContact(contact)
            } catch (error: any) {
                throw error.response
            }
        }
    }

    @Action({ rawError: true })
    async SET_CONTACT(contact: Contact) {
        try {
            const contactResponse = await ContactService.setContact(contact)
            this.setContact(contactResponse)
        } catch (error: any) {
            throw error.response
        }
    }

    // Getters
    get Contact(): Contact {
        return this.contact
    }
}
