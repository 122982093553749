export default class Job {
    public constructor(
        public readonly id: number = 1,
        public name: string = '',
        public context: string = '',
        public jobMissions: string = '',
        public profileLookedFor: string = '',
        public longTermObjective: string = '',
        public scorecardLink: string = '',
        public mustHave: string = '',
        public niceToHave: string = '',
        public noGo: string = '',
        public recruitmentProcess: string = '',
        public additionalInformation: string = '',
        public coachOpinion: string = '',
        public coachWarnings: string = '',
        public technoInformation: string = '',
        public salaryExplanation: string = '',
        public hubspotId: string = '',
        public hubspotJobTitle: string = '',
        public associatedMm: number = 1,
        public companyContact: number | null = null,
        public currentStatus: number = 1,
        public currentStatusString: string = '',
        public lastStatusUpdate: string = '',
        public generalMessage: string = '',
        public potentialManager: boolean = false,
        public educationType: string | null = null,
        public keywords: string = '',
        public jobCategory: Array<number> = [],
        public industry: number | null = null,
        public businessModel: number | null = null,
        public mbti: string | null = null,
        public minJobExperience: number | null = null,
        public experienceMin: number | null = null,
        public experienceMax: number | null = null,
        public maxFixedSalary: number | null = null,
        public maxVariableSalary: number | null = null,
        public automatchingRequired: Array<string> = [],
        public pitch: string = '',
        public pitchValidated: boolean = false,
        public isPublicForClient: boolean = true,
        public practice: number | null = null,
        public company: number = 1,
        public refusedFitsCount: number = 0,
        public remotePolicies: Array<number> = [],
        public fits: Array<any> = [],
        public matches: Array<any> = []
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(job: any): Job {
        return Object.assign(new Job(job.id), job)
    }
}
