import Vue from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Practice from '@/models/Practice'

import { Components } from '../models/Components'
import { ComponentElement } from '../models/ComponentElement'
import ComponentsService from '~/services/component-service'

@Module({
    name: 'components',
    stateFactory: true,
    namespaced: true,
})
export default class ComponentModule extends VuexModule {
    // State
    components: Components = null as any

    // Mutations
    @Mutation
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    setComponents(components: Components): any {
        this.components = components
        Vue.set(this.components, 'mbtis', [
            'INTJ- Architect',
            'INTP- Logician',
            'ENTJ- Commander',
            'ENTP- Debater',
            'INFJ- Advocate',
            'INFP- Mediator',
            'ENFJ- Protagonist',
            'ENFP- Campaigner',
            'ISTJ- Logistician',
            'ISFJ- Defender',
            'ESTJ- Executive',
            'ESFJ- Consul',
            'ISTP- Virtuoso',
            'ISFP- Adventurer',
            'ESTP- Entrepreneur',
            'ESFP- Entertainer',
        ])
        Vue.set(this.components, 'teams', [
            'Matchmaker',
            'Coach',
            'L33T',
            'Tech',
            'Market',
            'L33T',
            'Facturation',
            'Spine',
            'Autre',
        ])
        Vue.set(this.components, 'personas', [
            { displayName: 'Switcher', value: 'switcher' },
            { displayName: 'Shiny', value: 'shiny' },
            { displayName: 'Other', value: 'other' },
        ])
        Vue.set(this.components, 'remotePolicies', [
            { name: '100% remote', id: 1 },
            { name: 'Partial remote', id: 2 },
            { name: 'Mainly on site', id: 3 },
        ])
    }

    // Mutations
    @Mutation
    fetchPracticesSuccess(practices: Array<Practice>): void {
        Vue.set(this.components, 'practices', practices)
    }

    // Actions
    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async GET_COMPONENTS() {
        if (!this.components) {
            const components = await ComponentsService.getComponents()
            this.setComponents(components)
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_PRACTICES(): Promise<any> {
        try {
            const practices = await ComponentsService.getPractices()
            this.fetchPracticesSuccess(practices)
        } catch (error: any) {
            throw error.response
        }
    }

    // Getters
    get Components(): Components {
        return this.components
    }

    get componentValuesById() {
        return (values: Array<number>, component: string): Array<string> => {
            if (this.components && this.components[component]) {
                return this.components[component].reduce((array: Array<any>, value: ComponentElement) => {
                    if (values?.includes(value.id)) {
                        array.push(value)
                    }
                    return array
                }, [])
            }
            return []
        }
    }

    get componentNamesById() {
        return (values: Array<number>, component: string): Array<string> => {
            if (this.components && this.components[component]) {
                return this.components[component].reduce((array: Array<any>, value: ComponentElement) => {
                    if (values?.includes(value.id)) {
                        array.push(value.name)
                    }
                    return array
                }, [])
            }
            return []
        }
    }

    get componentNameById() {
        return (value: number, component: string): string => {
            if (this.components && this.components[component]) {
                const item = this.components[component].find((component) => value === component.id)
                return item?.name || ''
            }
            return ''
        }
    }

    get componentNameByIds() {
        return (values: Array<number>, component: string): Array<string> => {
            if (this.components && this.components[component]) {
                const items = this.components[component].filter((component) => values?.includes(component.id))
                return items.map((item) => item.name || '')
            }
            return []
        }
    }

    get componentsbyName() {
        return (componentList: string): Array<any> => {
            return (this.components || {})[componentList]
        }
    }

    get practiceName() {
        return (practiceId: number): string | undefined => {
            const practice = this.components.practices?.find((practice) => practice.id === practiceId)
            return practice?.name
        }
    }

    get industryName() {
        return (industryId: number): string | undefined => {
            const industry = this.components.industries?.find((industry) => industry.id === industryId)
            return industry?.name
        }
    }

    public get segmentations(): Array<any> {
        return this.components?.companyMeltingPots.filter((meltingPot) => meltingPot.category === 'target')
    }

    public get maturity(): Array<any> {
        return this.components?.companyMeltingPots.filter((meltingPot) => meltingPot.category === 'maturity')
    }

    /*
        This getter creates an array used for a v-autocomplete component
        The targeted structure is the following :
        [
            { header: 'Tech' },
            { JobObject },
            { JobObject },
            { divider: true },
            { header: 'Operations' },
            ...
        ]
    */
    public get jobsGroupedByCategories() {
        return (selectedIds: Array<number> = []): Array<any> => {
            // sorting jobs alphabetically
            const jobs = [...this.components.jobsWanted]?.sort((a, b) => {
                return a.name.localeCompare(b.name)
            })
            /*
                Creating dict with following structure :
                {
                    Tech: [Job, Job],
                    Operations: [Job],
                    [...]
                }
            */
            const groupedJobs: any = {}
            jobs.map((job) => {
                const isSelected = selectedIds.includes(job.id)
                if (!groupedJobs[job.category]) {
                    groupedJobs[job.category] = [{ ...job, isSelected }]
                } else {
                    groupedJobs[job.category].push({ ...job, isSelected })
                }
            })
            // Getting all categories and sorting them alphabetically
            const categories = Object.keys(groupedJobs)?.sort((a, b) => {
                return a.localeCompare(b)
            })

            // Creating targeted structure described above
            const formattedJobs: any[] = []
            categories.map((category) => {
                formattedJobs.push({ header: category })
                formattedJobs.push(...groupedJobs[category])
                formattedJobs.push({ divider: true })
            })
            return formattedJobs
        }
    }
}
