











import { Component, Vue } from 'vue-property-decorator'
import AppBar from '../components/bars/AppBar.vue'
import Toaster from '../components/Toaster.vue'

@Component({ components: { AppBar, Toaster } })
export default class Default extends Vue {
    loading = true

    async mounted() {
        this.loading = true
        await Promise.all([
            this.$store.dispatch('components/GET_COMPONENTS'),
            this.$store.dispatch('teamUsers/FETCH_TEAM_USERS'),
        ])
        await this.$store.dispatch('components/FETCH_PRACTICES')
        this.loading = false
    }
}
