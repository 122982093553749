





import { Component, Vue, Prop } from 'vue-property-decorator'

@Component
export default class Avatar extends Vue {
    @Prop({ default: '' })
    private image: string

    @Prop({ default: 30 })
    private size: number

    @Prop({ default: false })
    private border: boolean
}
