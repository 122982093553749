import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _632a1fe0 = () => interopDefault(import('../pages/candidate.vue' /* webpackChunkName: "pages/candidate" */))
const _1c946fce = () => interopDefault(import('../pages/candidate/_id/index.vue' /* webpackChunkName: "pages/candidate/_id/index" */))
const _7cb0ffe8 = () => interopDefault(import('../pages/candidate/_id/academy.vue' /* webpackChunkName: "pages/candidate/_id/academy" */))
const _2e0b8e43 = () => interopDefault(import('../pages/candidate/_id/activity.vue' /* webpackChunkName: "pages/candidate/_id/activity" */))
const _d33dbf2a = () => interopDefault(import('../pages/candidate/_id/autoSearch.vue' /* webpackChunkName: "pages/candidate/_id/autoSearch" */))
const _6c7a8be4 = () => interopDefault(import('../pages/candidate/_id/general.vue' /* webpackChunkName: "pages/candidate/_id/general" */))
const _1c36d6fd = () => interopDefault(import('../pages/candidate/_id/interview.vue' /* webpackChunkName: "pages/candidate/_id/interview" */))
const _86f81af0 = () => interopDefault(import('../pages/candidate/_id/matchesTalks.vue' /* webpackChunkName: "pages/candidate/_id/matchesTalks" */))
const _4bd7091c = () => interopDefault(import('../pages/candidate/_id/referrals.vue' /* webpackChunkName: "pages/candidate/_id/referrals" */))
const _f90571cc = () => interopDefault(import('../pages/company.vue' /* webpackChunkName: "pages/company" */))
const _4eb0a08d = () => interopDefault(import('../pages/company/_id/contacts.vue' /* webpackChunkName: "pages/company/_id/contacts" */))
const _b00aa7c4 = () => interopDefault(import('../pages/company/_id/general.vue' /* webpackChunkName: "pages/company/_id/general" */))
const _6eca4410 = () => interopDefault(import('../pages/company/_id/jobs.vue' /* webpackChunkName: "pages/company/_id/jobs" */))
const _2a7f5792 = () => interopDefault(import('../pages/home.vue' /* webpackChunkName: "pages/home" */))
const _38ec10da = () => interopDefault(import('../pages/job.vue' /* webpackChunkName: "pages/job" */))
const _6ef3dc31 = () => interopDefault(import('../pages/job/_id/autoSearch.vue' /* webpackChunkName: "pages/job/_id/autoSearch" */))
const _6ac8d5de = () => interopDefault(import('../pages/job/_id/general.vue' /* webpackChunkName: "pages/job/_id/general" */))
const _6a1f60ad = () => interopDefault(import('../pages/job/_id/missionSheet.vue' /* webpackChunkName: "pages/job/_id/missionSheet" */))
const _1f61347a = () => interopDefault(import('../pages/job/_id/permissions.vue' /* webpackChunkName: "pages/job/_id/permissions" */))
const _d6dab894 = () => interopDefault(import('../pages/job/_id/pitch.vue' /* webpackChunkName: "pages/job/_id/pitch" */))
const _4f9cd86c = () => interopDefault(import('../pages/job/_id/ratings.vue' /* webpackChunkName: "pages/job/_id/ratings" */))
const _43508146 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _15ea703b = () => interopDefault(import('../pages/ai_tools/aiTools.vue' /* webpackChunkName: "pages/ai_tools/aiTools" */))
const _71c7ac06 = () => interopDefault(import('../pages/ai_tools/kiaditca.js' /* webpackChunkName: "pages/ai_tools/kiaditca" */))
const _6d962586 = () => interopDefault(import('../pages/listing/candidate-admitted.vue' /* webpackChunkName: "pages/listing/candidate-admitted" */))
const _199a578b = () => interopDefault(import('../pages/listing/candidate-applications.vue' /* webpackChunkName: "pages/listing/candidate-applications" */))
const _7893ce7d = () => interopDefault(import('../pages/listing/candidate-applications/interested.vue' /* webpackChunkName: "pages/listing/candidate-applications/interested" */))
const _34b3bc9c = () => interopDefault(import('../pages/listing/candidate-applications/new.vue' /* webpackChunkName: "pages/listing/candidate-applications/new" */))
const _44a91191 = () => interopDefault(import('../pages/listing/candidate-applications/opport-check.vue' /* webpackChunkName: "pages/listing/candidate-applications/opport-check" */))
const _d4e16868 = () => interopDefault(import('../pages/listing/candidate-applications/refused.vue' /* webpackChunkName: "pages/listing/candidate-applications/refused" */))
const _4f1e0534 = () => interopDefault(import('../pages/listing/candidate-db.vue' /* webpackChunkName: "pages/listing/candidate-db" */))
const _f4c98e84 = () => interopDefault(import('../pages/listing/candidate-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals" */))
const _c8af7a6a = () => interopDefault(import('../pages/listing/candidate-referrals/no-referrals.vue' /* webpackChunkName: "pages/listing/candidate-referrals/no-referrals" */))
const _2b863c5d = () => interopDefault(import('../pages/listing/candidate-referrals/ref-created.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-created" */))
const _e9d550e8 = () => interopDefault(import('../pages/listing/candidate-referrals/ref-pending.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-pending" */))
const _9b1742ba = () => interopDefault(import('../pages/listing/candidate-referrals/ref-requested.vue' /* webpackChunkName: "pages/listing/candidate-referrals/ref-requested" */))
const _3fa0ece0 = () => interopDefault(import('../pages/listing/candidate-search.vue' /* webpackChunkName: "pages/listing/candidate-search" */))
const _4d5dc898 = () => interopDefault(import('../pages/listing/jobs.vue' /* webpackChunkName: "pages/listing/jobs" */))
const _d3fd3676 = () => interopDefault(import('../pages/listing/matches.vue' /* webpackChunkName: "pages/listing/matches" */))
const _54d4d118 = () => interopDefault(import('../pages/listing/matches/candidate-check.vue' /* webpackChunkName: "pages/listing/matches/candidate-check" */))
const _a5158acc = () => interopDefault(import('../pages/listing/matches/check-before-admission.vue' /* webpackChunkName: "pages/listing/matches/check-before-admission" */))
const _3fe56940 = () => interopDefault(import('../pages/listing/matches/client-check.vue' /* webpackChunkName: "pages/listing/matches/client-check" */))
const _51319ba6 = () => interopDefault(import('../pages/listing/matches/hired.vue' /* webpackChunkName: "pages/listing/matches/hired" */))
const _bc486e16 = () => interopDefault(import('../pages/listing/matches/interested-before-admission.vue' /* webpackChunkName: "pages/listing/matches/interested-before-admission" */))
const _34f8f532 = () => interopDefault(import('../pages/listing/matches/internal-check.vue' /* webpackChunkName: "pages/listing/matches/internal-check" */))
const _25113491 = () => interopDefault(import('../pages/listing/matches/interview-steps.vue' /* webpackChunkName: "pages/listing/matches/interview-steps" */))
const _a530bfdc = () => interopDefault(import('../pages/listing/matches/offer.vue' /* webpackChunkName: "pages/listing/matches/offer" */))
const _67cb01f4 = () => interopDefault(import('../pages/listing/matches/refused.vue' /* webpackChunkName: "pages/listing/matches/refused" */))
const _79a8de2f = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/candidate",
    component: _632a1fe0,
    name: "candidate",
    children: [{
      path: ":id",
      component: _1c946fce,
      name: "candidate-id"
    }, {
      path: ":id?/academy",
      component: _7cb0ffe8,
      name: "candidate-id-academy"
    }, {
      path: ":id?/activity",
      component: _2e0b8e43,
      name: "candidate-id-activity"
    }, {
      path: ":id?/autoSearch",
      component: _d33dbf2a,
      name: "candidate-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _6c7a8be4,
      name: "candidate-id-general"
    }, {
      path: ":id?/interview",
      component: _1c36d6fd,
      name: "candidate-id-interview"
    }, {
      path: ":id?/matchesTalks",
      component: _86f81af0,
      name: "candidate-id-matchesTalks"
    }, {
      path: ":id?/referrals",
      component: _4bd7091c,
      name: "candidate-id-referrals"
    }]
  }, {
    path: "/company",
    component: _f90571cc,
    name: "company",
    children: [{
      path: ":id?/contacts",
      component: _4eb0a08d,
      name: "company-id-contacts"
    }, {
      path: ":id?/general",
      component: _b00aa7c4,
      name: "company-id-general"
    }, {
      path: ":id?/jobs",
      component: _6eca4410,
      name: "company-id-jobs"
    }]
  }, {
    path: "/home",
    component: _2a7f5792,
    name: "home"
  }, {
    path: "/job",
    component: _38ec10da,
    name: "job",
    children: [{
      path: ":id?/autoSearch",
      component: _6ef3dc31,
      name: "job-id-autoSearch"
    }, {
      path: ":id?/general",
      component: _6ac8d5de,
      name: "job-id-general"
    }, {
      path: ":id?/missionSheet",
      component: _6a1f60ad,
      name: "job-id-missionSheet"
    }, {
      path: ":id?/permissions",
      component: _1f61347a,
      name: "job-id-permissions"
    }, {
      path: ":id?/pitch",
      component: _d6dab894,
      name: "job-id-pitch"
    }, {
      path: ":id?/ratings",
      component: _4f9cd86c,
      name: "job-id-ratings"
    }]
  }, {
    path: "/login",
    component: _43508146,
    name: "login"
  }, {
    path: "/ai_tools/aiTools",
    component: _15ea703b,
    name: "ai_tools-aiTools"
  }, {
    path: "/ai_tools/kiaditca",
    component: _71c7ac06,
    name: "ai_tools-kiaditca"
  }, {
    path: "/listing/candidate-admitted",
    component: _6d962586,
    name: "listing-candidate-admitted"
  }, {
    path: "/listing/candidate-applications",
    component: _199a578b,
    name: "listing-candidate-applications",
    children: [{
      path: "interested",
      component: _7893ce7d,
      name: "listing-candidate-applications-interested"
    }, {
      path: "new",
      component: _34b3bc9c,
      name: "listing-candidate-applications-new"
    }, {
      path: "opport-check",
      component: _44a91191,
      name: "listing-candidate-applications-opport-check"
    }, {
      path: "refused",
      component: _d4e16868,
      name: "listing-candidate-applications-refused"
    }]
  }, {
    path: "/listing/candidate-db",
    component: _4f1e0534,
    name: "listing-candidate-db"
  }, {
    path: "/listing/candidate-referrals",
    component: _f4c98e84,
    name: "listing-candidate-referrals",
    children: [{
      path: "no-referrals",
      component: _c8af7a6a,
      name: "listing-candidate-referrals-no-referrals"
    }, {
      path: "ref-created",
      component: _2b863c5d,
      name: "listing-candidate-referrals-ref-created"
    }, {
      path: "ref-pending",
      component: _e9d550e8,
      name: "listing-candidate-referrals-ref-pending"
    }, {
      path: "ref-requested",
      component: _9b1742ba,
      name: "listing-candidate-referrals-ref-requested"
    }]
  }, {
    path: "/listing/candidate-search",
    component: _3fa0ece0,
    name: "listing-candidate-search"
  }, {
    path: "/listing/jobs",
    component: _4d5dc898,
    name: "listing-jobs"
  }, {
    path: "/listing/matches",
    component: _d3fd3676,
    name: "listing-matches",
    children: [{
      path: "candidate-check",
      component: _54d4d118,
      name: "listing-matches-candidate-check"
    }, {
      path: "check-before-admission",
      component: _a5158acc,
      name: "listing-matches-check-before-admission"
    }, {
      path: "client-check",
      component: _3fe56940,
      name: "listing-matches-client-check"
    }, {
      path: "hired",
      component: _51319ba6,
      name: "listing-matches-hired"
    }, {
      path: "interested-before-admission",
      component: _bc486e16,
      name: "listing-matches-interested-before-admission"
    }, {
      path: "internal-check",
      component: _34f8f532,
      name: "listing-matches-internal-check"
    }, {
      path: "interview-steps",
      component: _25113491,
      name: "listing-matches-interview-steps"
    }, {
      path: "offer",
      component: _a530bfdc,
      name: "listing-matches-offer"
    }, {
      path: "refused",
      component: _67cb01f4,
      name: "listing-matches-refused"
    }]
  }, {
    path: "/",
    component: _79a8de2f,
    name: "index"
  }, {
    path: "/company/:id/",
    redirect: "/company/:id/general",
    name: "companyId"
  }, {
    path: "/job/:id/",
    redirect: "/job/:id/general",
    name: "jobId"
  }, {
    path: "/listing/matches/",
    redirect: "/listing/matches/internal-check",
    name: "listingMatches"
  }, {
    path: "/listing/application/",
    redirect: "/listing/candidate-applications/new",
    name: "candidateListing"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
