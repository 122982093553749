import 'vuetify/src/styles/main.sass'
import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

export default new Vuetify({
    customVariables: ['~/assets/variables.scss'],
    theme: {
        options: { customProperties: true },
        treeShake: true,
        dark: false,
        themes: {
            dark: {
                primary: '#607d8b',
                lightBlue: '#8eacbb',
                darkBlue: '#34515e',
                whiteGrey: '#eceff1',
                lightGrey: '#ced7db',
                darkGrey: '#9da6a9',
                error: '#E95167',
                active: '#118BE1',
                warning: '#FDB479',
                success: '#42A874',
            },
            light: {
                primary: '#607d8b',
                lightBlue: '#8eacbb',
                darkBlue: '#34515e',
                lightGrey: '#ced7db',
                whiteGrey: '#eceff1',
                darkGrey: '#9da6a9',
                error: '#E95167',
                active: '#118BE1',
                warning: '#FDB479',
                success: '#42A874',
            },
        },
    },
})
