//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LeftNavigationDrawer from '../navigation-drawers/LeftNavigationDrawer.vue'
import Avatar from '../Avatar.vue'
import SearchBar from '../search-bar/SearchBar.vue'

export default {
    components: {
        LeftNavigationDrawer,
        SearchBar,
        Avatar,
    },
    props: {
        menus: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            clipped: false,
            fixed: false,
            drawer: false,
            expand: false,
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.xs
        },
    },
    methods: {
        expanding() {
            this.expand = true
        },

        closeDrawer() {
            this.drawer = false
        },
    },
}
