export class Contact {
    public constructor(
        public readonly id: number,
        public readonly user: number = 1,
        public readonly firstName: string = '',
        public readonly lastName: string = '',
        public email: string = '',
        public calendarEmbeddedUrl: string = '',
        public pictureUrl: string = '',
        public phone: string = '',
        public city: string = '',
        public gender: string = '',
        public position: string = '',
        public readonly hubspotId?: string,
        public permission?: string
    ) {}

    getFullName(): string {
        return `${this.firstName} ${this.lastName}`
    }

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(contact: any): Contact {
        return Object.assign(new Contact(contact.id), contact)
    }
}
