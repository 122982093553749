//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    data() {
        return {
            show: false,
        }
    },
    computed: {
        toaster() {
            return this.$store.getters['toasters/Toaster'] || {}
        },
    },
    watch: {
        toaster(newVal) {
            // We show trigger if the new toaster is not an empty object
            if (Object.keys(newVal).length !== 0) {
                this.show = true
            }
        },
    },
    methods: {
        closeToaster() {
            this.show = false
            // We use setTimeout in order to have a clean fade transition before removing the toaster from the store
            setTimeout(() => this.$store.dispatch('toasters/REMOVE_TOASTER'), 500)
        },
    },
}
