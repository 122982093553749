import { Referral } from '../models/Referral'
import { client } from '../plugins/axios-plugin'

export default class ReferralService {
    static async fetchReferrals(id: number): Promise<any> {
        const response = await client.get(`/candidates/${id}/referrals/`)
        return response.data
    }

    static async postReferral(candidateId: number): Promise<any> {
        const response = await client.post(`candidates/${candidateId}/referrals/`)
        return response?.data
    }

    static async addReferral(candidateId: number): Promise<Referral> {
        const referral = await this.postReferral(candidateId)
        return Referral.fromObject(referral)
    }

    static async patchReferral(
        id: number,
        candidateId: number,
        field: string,
        value: string | Array<any> | boolean | number | unknown
    ): Promise<Referral> {
        const response = await client.patch(`/candidates/${candidateId}/referrals/${id}/`, { [field]: value })
        return response?.data
    }

    static async editReferral(referral: Referral, field: keyof Referral & string): Promise<Referral> {
        const value = referral[field]
        const response = await client.patch(`/candidates/${referral.candidate}/referrals/${referral.id}/`, {
            [field]: value,
        })
        return response.data
    }

    static async deleteReferral(id: number, candidateId: number): Promise<Referral> {
        const response = await client.delete(`/candidates/${candidateId}/referrals/${id}/`)
        return response.data
    }

    static async patchReferralStatus(
        id: number,
        candidateId: number,
        action: string,
        sequence: number
    ): Promise<Referral> {
        const response = await client.patch(`/candidates/${candidateId}/referrals/${id}/next_status/`, {
            action,
            sequence,
        })
        return response?.data
    }

    static async setReferralStatus(
        id: number,
        candidateId: number,
        action: string,
        sequence: number
    ): Promise<Referral> {
        const patchedReferral = await this.patchReferralStatus(id, candidateId, action, sequence)
        return Referral.fromObject(patchedReferral)
    }
}
