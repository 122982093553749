import Vue from 'vue'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { Contact } from '~/models/Contact'
import CompanyContactService from '~/services/company-contact-service'
import ContactService from '~/services/contact-service'

@Module({
    name: 'companyContacts',
    stateFactory: true,
    namespaced: true,
})
export default class CompanyContactsModule extends VuexModule {
    /* Items is a dictionnary with id as key and Contact as value
        Example : {
            1: Contact,
            23: Contact
        }
    */
    items: Record<number, Contact> = {}

    /*
        Collections is a dictionnary with string as key and an array of ids as value
        The numbers in those values are pointing to the keys store in the items
        Example : {
            contactsForCompany1: [number, number, number],
        }
    */
    collections: Record<string, [number]> = {}

    // Getters
    get ContactsByIds() {
        return (ids: Array<number>): Array<Contact> => {
            // I added filter here to avoid having undefined return in the array
            return ids.map((id) => this.items[id]).filter((item) => item)
        }
    }

    get ContactsForCompanyId() {
        return (companyId: string): Array<Contact> => {
            if (!this.collections[`contactsForCompany${companyId}`]) {
                return []
            }
            // I added filter here to avoid having undefined return in the array
            return this.collections[`contactsForCompany${companyId}`].map((id) => this.items[id]).filter((item) => item)
        }
    }

    // Mutations
    @Mutation
    fetchContactsSuccess(params: { contacts: Array<Contact>; companyId: number }): void {
        // We store contacts in this.items
        params.contacts.map((item) => {
            Vue.set(this.items, item.id, item)
        })
        // We create the field contactsForCompanyX in this.collections with the ids of the returned contacts
        if (params.companyId) {
            const contactIds: Array<number> = params.contacts.map((item) => item.id)
            Vue.set(this.collections, `contactsForCompany${params.companyId}`, contactIds)
        }
    }

    @Mutation
    updateContact({ contactId, updatedContact }: { contactId: number; updatedContact: Contact }) {
        Vue.set(this.items, contactId, updatedContact)
    }

    @Action({ rawError: true })
    async FETCH_CONTACTS(companyId: number): Promise<any> {
        try {
            const contacts = await CompanyContactService.getContacts(companyId)
            this.fetchContactsSuccess({ contacts, companyId })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async SEND_ACCESS_TRIGGER(data: any): Promise<any> {
        try {
            await CompanyContactService.sendAccessTrigger(data.companyId, data.contactId, data.triggerId)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async HIJACK_COMPANY_CONTACT(contactId: number): Promise<void> {
        try {
            const hijackUrl = await CompanyContactService.hijack(contactId)
            window.open(hijackUrl, '_blank')
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async UPDATE_CALENDAR_URL({
        userId,
        calendarEmbeddedUrl,
        companyId,
        contactId,
    }: {
        userId: number
        calendarEmbeddedUrl: string
        companyId: number
        contactId: number
    }): Promise<void> {
        try {
            await ContactService.patchContactUser(userId, { calendarEmbeddedUrl })
            const updatedContact = await CompanyContactService.getContact(companyId, contactId)
            this.context.commit('updateContact', { contactId, updatedContact })
        } catch (error: any) {
            throw error.response
        }
    }
}
