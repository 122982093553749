import Job from '@/models/Job'
import { client } from '../plugins/axios-plugin'

type FieldValue = string | Array<any> | boolean | number | unknown

export default class JobService {
    static async fetchJob(id: string): Promise<Job> {
        const response = await client.get(`/jobs/${id}/`)
        return Job.fromObject(response.data)
    }

    static async fetchActiveJobs(id: string): Promise<Job[]> {
        const response = await client.get(`/companies/${id}/active-jobs/`)
        return response.data
    }

    static async fetchAllJobs(id: string): Promise<Job[]> {
        const response = await client.get(`/companies/${id}/jobs/`)
        return response.data
    }

    static async fetchCities(jobId: string): Promise<any> {
        const response = await client.get(`/jobs/${jobId}/cities`)
        return response.data?.results
    }

    static async fetchLanguages(jobId: string): Promise<any> {
        const response = await client.get(`/jobs/${jobId}/languages`)
        return response.data?.results
    }

    static async patchJob(
        id: number,
        field: string,
        value: string | Array<any> | boolean | number | unknown
    ): Promise<Job> {
        const response = await client.patch(`/jobs/${id}/`, { [field]: value })
        return Job.fromObject(response?.data)
    }

    static async massPatchJob(id: number, fields: Record<string, FieldValue>): Promise<Job> {
        const response = await client.patch(`/jobs/${id}/`, fields)
        return Job.fromObject(response?.data)
    }

    static async launchAutoSearch(jobId: number): Promise<any> {
        await client.post(`/jobs/${jobId}/fits/`)
    }

    static async getMyJobsWithFits(): Promise<any> {
        const response = await client.get('jobs/my-jobs-with-fits')
        return response.data?.results
    }

    static async fetchMatchesForJob(jobId: number): Promise<any> {
        const response = await client.get(`matches/?job=${jobId}`)
        return response.data?.results
    }
}
