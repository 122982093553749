import Company from '@/models/Company'
import Job from '@/models/Job'
import { client } from '../plugins/axios-plugin'

export default class CompanyService {
    static async getCompany(id: number): Promise<Company> {
        const response = await client.get(`/companies/${id}/`)
        return Company.fromObject(response.data)
    }

    static async putCompany(company: Company): Promise<any> {
        const response = await client.patch(`/companies/${company.id}/`, company)
        return Company.fromObject(response?.data)
    }

    static async patchCompany(
        id: number,
        field: string,
        value: string | Array<any> | boolean | number | unknown
    ): Promise<Company> {
        const response = await client.patch(`/companies/${id}/`, { [field]: value })
        return Company.fromObject(response?.data)
    }
}
