export default class Match {
    currentStep: any
    currentStepDate: any
    public constructor(public readonly id: number, public creatorType = '') {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(match: any): Match {
        return Object.assign(new Match(match.id, match.creatorType), match)
    }
}
