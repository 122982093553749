import { client } from '../plugins/axios-plugin'
import { Experience } from '../models/Experience'

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

export default class ExperiencesService {
    static async getExperiences(candidateId: number): Promise<any> {
        const response = await client.get(`/candidates/${candidateId}/experiences/`)
        return response?.data
    }

    static async postExperience(candidateId: number): Promise<any> {
        const response = await client.post(`candidates/${candidateId}/experiences/`, { candidate: candidateId })
        return response?.data
    }

    static async addExperience(candidateId: number): Promise<Experience> {
        const experience = await this.postExperience(candidateId)
        return Experience.fromObject(experience)
    }

    // if field is of type string, experience[field] will throw an error because field should be a keyof
    // experience, so here we need to say that field is a key of experience AND a string because we
    // pass a string to editExperience.

    static async patchExperience(experience: Experience, field: keyof Experience & string): Promise<Experience> {
        const value = experience[field]
        const response = await client.patch(`/candidates/${experience.candidate}/experiences/${experience.id}/`, {
            [field]: value,
        })
        return response?.data
    }

    static async deleteExperience(id: number, candidateId: number) {
        await client.delete(`candidates/${candidateId}/experiences/${id}/`)
    }

    static async removeExperience(id: number, candidateId: number) {
        await this.deleteExperience(id, candidateId)
    }
}
