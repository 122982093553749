/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Vue from 'vue'

import { PrioritisedJob, PrioritisedCity } from '~/models'
import CandidateService from '~/services/candidate-service'
import { Candidate } from '~/models/Candidate'
import { Contact } from '~/models/Contact'

type pagination = {
    count: number
    next: number | null
    previous: number | null
}

const extractPage = (url: string): number | null => {
    const urlObject = new URL(url)
    const query = new URLSearchParams(urlObject.search)
    const page = query.get('page')
    if (page === null) {
        return null
    }
    return parseInt(page)
}

@Module({
    name: 'candidates',
    stateFactory: true,
    namespaced: true,
})
export default class CandidateModule extends VuexModule {
    // State

    items: Record<number, Candidate> = {}
    currentCandidateIdToDisplay = 0
    collections: Record<string, [number]> = {}

    paginations: Record<string, pagination> = {}

    // Getters

    get CandidateById() {
        return (id: number): Candidate => {
            return this.items[id]
        }
    }

    get CurrentCandidateToDisplay() {
        return this.items[this.currentCandidateIdToDisplay]
    }

    get Collection() {
        return (collectionName: string): Array<number> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName]
        }
    }

    get CandidatesForCollection() {
        return (collectionName: string): Array<Candidate> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName].map((id) => this.items[id])
        }
    }

    get NextPageForCollection() {
        return (collectionName: string): number | null => {
            if (!this.paginations[collectionName]) {
                return null
            }
            return this.paginations[collectionName].next
        }
    }

    get HasMoreForCollection() {
        return (collectionName: string): boolean => {
            return !!this.paginations[collectionName]?.next
        }
    }

    get CountForCollection() {
        return (collectionName: string): number | null => {
            if (!this.paginations[collectionName]) {
                return null
            }
            return this.paginations[collectionName].count
        }
    }

    get isCandidateFullRemote() {
        return (id: number): boolean => {
            const candidate = this.items[id]
            return candidate.remotePolicies?.length === 1 && candidate.remotePolicies?.includes(1)
        }
    }

    // Mutations

    @Mutation
    setItem(candidate: Candidate) {
        // if the item is already in the list we replace it, otherwise we create it
        Vue.set(this.items, candidate.id, candidate)
    }

    @Mutation
    setFullName({ contact, candidateId }: { contact: Contact; candidateId: number }) {
        const candidate = this.items[candidateId]
        candidate.firstName = contact.firstName
        candidate.lastName = contact.lastName
    }

    @Mutation
    addJob(job: PrioritisedJob) {
        const candidateId = job.candidate
        this.items[candidateId].jobsWanted.push(job)
    }

    @Mutation
    setJob(job: PrioritisedJob) {
        const candidateId = job.candidate
        const indexOfJobToEdit = this.items[candidateId].jobsWanted.findIndex((jobWanted) => jobWanted.id === job.id)
        this.items[candidateId].jobsWanted.splice(indexOfJobToEdit, 1, job)
    }

    @Mutation
    removeJob({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].jobsWanted = this.items[candidateId].jobsWanted?.filter((job) => job.id !== id)
    }

    @Mutation
    setTechno({ technology, candidateId }: { technology: any; candidateId: number }) {
        const indexOfTechnoToEdit = this.items[candidateId].technologies.findIndex(
            (techno) => technology.id === techno.id
        )
        this.items[candidateId].technologies.splice(indexOfTechnoToEdit, 1, technology)
    }

    @Mutation
    addTechno(technology: any) {
        this.items[technology.candidate].technologies.push(technology)
    }

    @Mutation
    removeTechno({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].technologies = this.items[candidateId].technologies?.filter(
            (techno) => techno.id !== id
        )
    }

    @Mutation
    setIndustry({ industry, candidateId }: { industry: any; candidateId: number }) {
        const indexOfIndustryToEdit = this.items[candidateId].industries.findIndex(
            (industryItem) => industry.id === industryItem.id
        )
        this.items[candidateId].industries.splice(indexOfIndustryToEdit, 1, industry)
    }

    @Mutation
    addIndustry(industry: any) {
        this.items[industry.candidate].industries.push(industry)
    }

    @Mutation
    removeIndustry({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].industries = this.items[candidateId].industries?.filter(
            (industryItem) => industryItem.id !== id
        )
    }

    @Mutation
    setBusinessModel({ businessModel, candidateId }: { businessModel: any; candidateId: number }) {
        const indexOfBusinessModelToEdit = this.items[candidateId].businessModels.findIndex(
            (model) => businessModel.id === model.id
        )
        this.items[businessModel.candidate].businessModels.splice(indexOfBusinessModelToEdit, 1, businessModel)
    }

    @Mutation
    addBusinessModel(businessModel: any) {
        this.items[businessModel.candidate].businessModels.push(businessModel)
    }

    @Mutation
    removeBusinessModel({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].businessModels = this.items[candidateId].businessModels?.filter(
            (model) => model.id !== id
        )
    }

    @Mutation
    setLanguage(language: any) {
        const indexOfLanguageToEdit = this.items[language.candidate].languages.findIndex(
            (lang) => language.id === lang.id
        )
        this.items[language.candidate].languages.splice(indexOfLanguageToEdit, 1, language)
    }

    @Mutation
    addLanguage(language: any) {
        this.items[language.candidate].languages.push(language)
    }

    @Mutation
    removeLanguage({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].languages = this.items[candidateId].languages?.filter((language) => language.id !== id)
    }

    @Mutation
    setMaturity(maturity: any) {
        const indexOfMaturityToEdit = this.items[maturity.candidate].maturities.findIndex(
            (maturityItem) => maturity.id === maturityItem.id
        )
        this.items[maturity.candidate].maturities.splice(indexOfMaturityToEdit, 1, maturity)
    }

    @Mutation
    addMaturity(maturity: any) {
        this.items[maturity.candidate].maturities.push(maturity)
    }

    @Mutation
    removeMaturity({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].maturities = this.items[candidateId].maturities?.filter(
            (maturity) => maturity.id !== id
        )
    }

    @Mutation
    setSegmentation(segmentation: any) {
        const indexOfSegmentationToEdit = this.items[segmentation.candidate].segmentations.findIndex(
            (segmentationItem) => segmentation.id === segmentationItem.id
        )
        this.items[segmentation.candidate].segmentations.splice(indexOfSegmentationToEdit, 1, segmentation)
    }

    @Mutation
    addSegmentation(segmentation: any) {
        this.items[segmentation.candidate].segmentations.push(segmentation)
    }

    @Mutation
    removeSegmentation({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].segmentations = this.items[candidateId].segmentations?.filter(
            (segmentation) => segmentation.id !== id
        )
    }

    @Mutation
    addCity(city: PrioritisedCity) {
        const candidateId = city.candidate
        this.items[candidateId].citiesWanted.push(city)
    }

    @Mutation
    setCity(city: PrioritisedCity) {
        const candidateId = city.candidate
        const indexOfCityToEdit = this.items[candidateId].citiesWanted.findIndex(
            (cityWanted) => cityWanted.id === city.id
        )
        this.items[candidateId].citiesWanted.splice(indexOfCityToEdit, 1, city)
    }

    @Mutation
    removeCity({ id, candidateId }: { id: number; candidateId: number }): any {
        this.items[candidateId].citiesWanted = this.items[candidateId].citiesWanted?.filter((city) => city.id !== id)
    }

    @Mutation
    setCurrentCandidateToDisplay(jobId: number) {
        this.currentCandidateIdToDisplay = jobId
    }

    @Mutation
    setRefusedFitsCount({ id, length }: { id: number; length: number }) {
        this.items[id].refusedFitsCount += length
    }

    @Mutation
    resetRefusedFitsCount(id: number) {
        this.items[id].refusedFitsCount = 0
    }

    @Mutation
    setCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        Vue.set(this.collections, collectionName, ids)
    }

    @Mutation
    appendToCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        const allIds = (this.collections[collectionName] || []).concat(ids)
        const uniqIds = [...new Set(allIds)]
        Vue.set(this.collections, collectionName, uniqIds)
    }

    @Mutation
    setPagination({ pagination, collectionName }: { pagination: pagination; collectionName: string }): void {
        Vue.set(this.paginations, collectionName, pagination)
    }

    @Mutation
    setCollectionCount({ collectionName, length }: { collectionName: string; length: number }): void {
        this.paginations[collectionName].count -= length
    }

    @Mutation
    setItems({ candidates }: { candidates: Array<Candidate> }) {
        candidates.forEach((item: Candidate) => {
            Vue.set(this.items, item.id, Candidate.fromObject(item))
        })
    }

    @Mutation
    removeFromCollection({ item, collectionName }: { item: number; collectionName: string }): void {
        const index = this.collections[collectionName].indexOf(item)
        Vue.delete(this.collections[collectionName], index)
    }

    @Mutation
    toggleFromCollection({ id, collectionName }: { id: number; collectionName: string }) {
        if (this.collections[collectionName]) {
            const index = this.collections[collectionName].indexOf(id)
            if (index >= 0) {
                Vue.delete(this.collections[collectionName], index)
            } else {
                this.collections[collectionName].push(id)
            }
        } else {
            Vue.set(this.collections, collectionName, [id])
        }
    }

    @Mutation
    deleteCollection(collectionName: string) {
        Vue.delete(this.collections, collectionName)
    }

    // Actions
    @Action({ rawError: true })
    async FETCH_CANDIDATE(id: number) {
        try {
            const candidate = await CandidateService.getCandidate(id)
            this.setItem(candidate)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async SET_CANDIDATE(cand: Candidate) {
        const candidate = await CandidateService.setCandidate(cand)
        this.setItem(candidate)
    }

    @Action
    async SET_CANDIDATE_FIELD({ model, field }: { model: Candidate; field: keyof Candidate & string }) {
        const candidate = await CandidateService.setCandidateField(model, field)
        this.setItem(candidate)
    }

    @Action
    async SET_CANDIDATE_FIELDS({ model, fields }: { model: Candidate; fields: Array<keyof Candidate & string> }) {
        try {
            const candidateResponse = await CandidateService.setCandidateFields(model, fields)
            this.setItem(candidateResponse)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async SET_CANDIDATE_PRACTICE(patchingObject: any) {
        const candidate = await CandidateService.setCandidateField(patchingObject.model, patchingObject.field)
        this.setItem(candidate)
    }

    @Action
    async SET_CANDIDATE_STATUS(patchingObject: any) {
        try {
            const candidate = await CandidateService.setCandidateStatus(
                patchingObject.candidateId,
                patchingObject.action,
                patchingObject.sequence
            )
            this.setItem(candidate)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async HIJACK_CANDIDATE(candidateUser: number) {
        try {
            const hijackUrl = await CandidateService.hijackCandidate(candidateUser)
            window.open(hijackUrl, '_blank')
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async LAUNCH_AUTO_SEARCH(candidateId: number) {
        try {
            await CandidateService.launchAutoSearch(candidateId)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async ADD_JOB(newJob: PrioritisedJob): Promise<any> {
        try {
            const job = await CandidateService.addJob(newJob)
            this.addJob(job)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_JOB(newJob: PrioritisedJob): Promise<any> {
        try {
            const job = await CandidateService.editJob(newJob)
            this.setJob(job)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async REMOVE_JOB({ priority, candidateId }: { priority: number; candidateId: number }) {
        const candidate = this.items[candidateId]
        const jobsToRemove = CandidateModule.findJobsToRemove(priority, candidate.jobsWanted)
        await jobsToRemove.forEach((jobObject) => {
            CandidateService.removeJob(jobObject.id, candidateId)
            this.removeJob({ id: jobObject.id, candidateId })
        })
    }

    @Action({ rawError: true })
    public async ADD_TECHNO(newTechno: any): Promise<any> {
        try {
            const techno = await CandidateService.postTechno(newTechno)
            this.addTechno(techno)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_TECHNO(newTechno: any): Promise<any> {
        try {
            const techno = await CandidateService.patchTechno(newTechno)
            this.setTechno({ technology: techno, candidateId: newTechno.candidate })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async REMOVE_TECHNO({ techno, candidateId }: { techno: any; candidateId: number }): Promise<any> {
        try {
            await CandidateService.removeTechno(techno.id, candidateId)
            this.removeTechno({ id: techno.id, candidateId })
        } catch (error: any) {
            throw error.response
        }
    }

    // In this function we want to add a mastered industry
    // A CandidateIndustry can either be mastered (mastery = 1), wished (preference = 1) or in no go (preference = 3)
    // This is why we need to check if the industry is already wished/in no go.
    // If it's the case we need to edit the CandidateIndustry, if not we can create one.
    @Action({ rawError: true })
    public async ADD_INDUSTRY_MASTERED(newIndustry: any): Promise<any> {
        try {
            const industryToAddAlreadyExists = this.items[newIndustry.candidate].industries.find(
                (indus) => indus.industry === newIndustry.industry
            )

            // If we try to create a mastered industry that already exists in wished/no go industries,
            // we need to use PATCH instead of POST
            if (industryToAddAlreadyExists) {
                const patchingObject = {
                    candidate: newIndustry.candidate,
                    id: industryToAddAlreadyExists.id,
                    data: { mastery: 1 },
                }
                const industry = await CandidateService.patchIndustry(patchingObject)
                this.setIndustry({ industry, candidateId: newIndustry.candidate })
            } else {
                const industry = await CandidateService.postIndustry(newIndustry)
                this.addIndustry(industry)
            }
        } catch (error: any) {
            throw error.response
        }
    }

    // In this function we want to add a wished/no go industry
    // A CandidateIndustry can either be mastered (mastery = 1), wished (preference = 1) or in no go (preference = 3)
    // This is why we need to check if the industry is already mastered or in no go.
    // If it's the case we need to edit the CandidateIndustry, if not we can create one.
    @Action({ rawError: true })
    public async ADD_INDUSTRY_PREFERENCE(newIndustry: any): Promise<any> {
        try {
            const industryToAddAlreadyExists = this.items[newIndustry.candidate].industries.find(
                (industryItem) => industryItem.industry === newIndustry.industry
            )

            // If we try to create a wished industry that already exists,
            // we need to use PATCH instead of POST
            if (industryToAddAlreadyExists) {
                const patchingObject = {
                    candidate: newIndustry.candidate,
                    id: industryToAddAlreadyExists.id,
                    data: { preference: newIndustry.preference },
                }
                const industry = await CandidateService.patchIndustry(patchingObject)
                this.setIndustry({ industry, candidateId: newIndustry.candidate })
            } else {
                const industry = await CandidateService.postIndustry(newIndustry)
                this.addIndustry(industry)
            }
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_INDUSTRY(newIndustry: any): Promise<any> {
        try {
            const industry = await CandidateService.patchIndustry(newIndustry)
            this.setIndustry({ industry, candidateId: newIndustry.candidate })
        } catch (error: any) {
            throw error.response
        }
    }

    // In this function we want to remove a mastered industry
    // A CandidateIndustry can either be mastered (mastery = 1), wished (preference = 1) or in no go (preference = 3)
    // This is why we need to check if the industry is also wished/in no go.
    // If it's the case we need to edit the CandidateIndustry to reset mastery, if not we can remove the CandidateIndustry.
    @Action({ rawError: true })
    public async REMOVE_INDUSTRY_MASTERED({
        industryItem,
        candidateId,
    }: {
        industryItem: any
        candidateId: number
    }): Promise<any> {
        try {
            const industryToRemove = this.items[candidateId].industries.find(
                (candidateIndustryItem) => candidateIndustryItem.industry === industryItem.industry
            )
            // If indus is also wished/in no go we need to edit the CandidateIndustry else we delete it
            if (industryToRemove && industryToRemove.preference) {
                const newIndustryToEdit = {
                    ...industryToRemove,
                    data: { mastery: null },
                }
                const newIndustry = await CandidateService.patchIndustry(newIndustryToEdit)
                this.setIndustry({ industry: newIndustry, candidateId: newIndustry.candidate })
            } else {
                CandidateService.removeIndustry(industryItem.id, candidateId)
                this.removeIndustry({ id: industryItem.id, candidateId })
            }
        } catch (error: any) {
            throw error.response
        }
    }

    // In this function we want to remove a wished/no go industry
    // A CandidateIndustry can either be mastered (mastery = 1), wished (preference = 1) or in no go (preference = 3)
    // This is why we need to check if the industry is also mastered.
    // If it's the case we need to edit the CandidateIndustry to reset preference, if not we can remove the CandidateIndustry.
    @Action({ rawError: true })
    public async REMOVE_INDUSTRY_PREFERENCE({
        industryItem,
        candidateId,
    }: {
        industryItem: any
        candidateId: number
    }): Promise<any> {
        try {
            const industryToRemove = this.items[candidateId].industries.find(
                (candidateIndustryItem) => candidateIndustryItem.industry === industryItem.industry
            )
            // If industry is also mastered we need to edit the CandidateIndustry else we delete it
            if (industryToRemove && industryToRemove.mastery === 1) {
                const newIndustryToEdit = {
                    ...industryToRemove,
                    data: { preference: null },
                }
                const newIndustry = await CandidateService.patchIndustry(newIndustryToEdit)
                this.setIndustry({ industry: newIndustry, candidateId: newIndustry.candidate })
            } else {
                CandidateService.removeIndustry(industryItem.id, candidateId)
                this.removeIndustry({ id: industryItem.id, candidateId })
            }
        } catch (error: any) {
            throw error.response
        }
    }

    // A CandidateBusinessModel can either have a mastery and preference
    // This is why we need to check if the business model already has a mastery level defined
    // If it's the case we need to edit the CandidateBusinessModel, if not we can create one.
    @Action({ rawError: true })
    public async ADD_BUSINESS_MODEL_PREFERENCE(newModel: any): Promise<any> {
        try {
            const businessModelToAddAlreadyExists = this.items[newModel.candidate].businessModels.find(
                (businessModelItem) => businessModelItem.businessModel === newModel.businessModel
            )
            if (businessModelToAddAlreadyExists) {
                const patchingObject = {
                    candidate: newModel.candidate,
                    id: businessModelToAddAlreadyExists.id,
                    data: { preference: newModel.preference },
                }
                const businessModel = await CandidateService.patchBusinessModel(patchingObject)
                this.setBusinessModel({ businessModel, candidateId: newModel.candidate })
            } else {
                const model = await CandidateService.postBusinessModel(newModel)
                this.addBusinessModel(model)
            }
        } catch (error: any) {
            throw error.response
        }
    }

    // A CandidateBusinessModel can either have a mastery and preference
    // This is why we need to check if the business model already has a preference level defined
    // If it's the case we need to edit the CandidateBusinessModel, if not we can create one.
    @Action({ rawError: true })
    public async ADD_BUSINESS_MODEL_MASTERY(newModel: any): Promise<any> {
        try {
            const businessModelToAddAlreadyExists = this.items[newModel.candidate].businessModels.find(
                (businessModelItem) => businessModelItem.businessModel === newModel.businessModel
            )
            if (businessModelToAddAlreadyExists) {
                const patchingObject = {
                    candidate: newModel.candidate,
                    id: businessModelToAddAlreadyExists.id,
                    data: { mastery: newModel.mastery },
                }
                const businessModel = await CandidateService.patchBusinessModel(patchingObject)
                this.setBusinessModel({ businessModel, candidateId: newModel.candidate })
            } else {
                const model = await CandidateService.postBusinessModel(newModel)
                this.addBusinessModel(model)
            }
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_BUSINESS_MODEL(newModel: any): Promise<any> {
        try {
            const model = await CandidateService.patchBusinessModel(newModel)
            this.setBusinessModel(model)
        } catch (error: any) {
            throw error.response
        }
    }

    // A CandidateBusinessModel can either have a mastery or preference level defined
    // This is why we need to check if the business model is also mastered.
    // If it's the case we need to edit the CandidateBusinessModel to reset preference, if not we can remove the CandidateBusinessModel.
    @Action({ rawError: true })
    public async REMOVE_BUSINESS_MODEL_PREFERENCE({
        businessModel,
        candidateId,
    }: {
        businessModel: any
        candidateId: number
    }): Promise<any> {
        try {
            const modelToRemove = this.items[candidateId].businessModels.find((model) => model.id === businessModel.id)
            if (modelToRemove && modelToRemove.mastery === 1) {
                const newBusinessModelToEdit = {
                    ...modelToRemove,
                    data: { preference: null },
                }
                const newBusinessModel = await CandidateService.patchBusinessModel(newBusinessModelToEdit)
                this.setBusinessModel({ businessModel: newBusinessModel, candidateId: newBusinessModel.candidate })
            } else {
                CandidateService.removeBusinessModel(businessModel.id, candidateId)
                this.removeBusinessModel({ id: businessModel.id, candidateId })
            }
        } catch (error: any) {
            throw error.response
        }
    }

    // A CandidateBusinessModel can either have a mastery or preference level defined
    // This is why we need to check if the business model is also mastered.
    // If it's the case we need to edit the CandidateBusinessModel to reset mastery, if not we can remove the CandidateBusinessModel.
    @Action({ rawError: true })
    public async REMOVE_BUSINESS_MODEL_MASTERY({
        businessModel,
        candidateId,
    }: {
        businessModel: any
        candidateId: number
    }): Promise<any> {
        try {
            const modelToRemove = this.items[candidateId].businessModels.find((model) => model.id === businessModel.id)
            if (modelToRemove && modelToRemove.preference) {
                const newBusinessModelToEdit = {
                    ...modelToRemove,
                    data: { mastery: null },
                }
                const newBusinessModel = await CandidateService.patchBusinessModel(newBusinessModelToEdit)
                this.setBusinessModel({ businessModel: newBusinessModel, candidateId: newBusinessModel.candidate })
            } else {
                CandidateService.removeBusinessModel(businessModel.id, candidateId)
                this.removeBusinessModel({ id: businessModel.id, candidateId })
            }
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async ADD_LANGUAGE(newLanguage: any): Promise<any> {
        try {
            const language = await CandidateService.postLanguage(newLanguage)
            this.addLanguage(language)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_LANGUAGE(newLanguage: any): Promise<any> {
        try {
            const language = await CandidateService.patchLanguage(newLanguage)
            this.setLanguage(language)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async REMOVE_LANGUAGE({ language, candidateId }: { language: any; candidateId: number }) {
        try {
            const languageToRemove = this.items[candidateId].languages.find((lang) => lang.id === language.id)
            await CandidateService.removeLanguage(languageToRemove.id, candidateId)
            this.removeLanguage({ id: languageToRemove.id, candidateId })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async ADD_MATURITY(newMaturity: any): Promise<any> {
        try {
            const maturity = await CandidateService.postMaturity(newMaturity)
            this.addMaturity(maturity)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_MATURITY(newMaturity: any): Promise<any> {
        try {
            const maturity = await CandidateService.patchMaturity(newMaturity)
            this.setMaturity(maturity)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async REMOVE_MATURITY({ maturity, candidateId }: { maturity: any; candidateId: number }) {
        try {
            const maturityToRemove = this.items[candidateId].maturities.find(
                (maturityItem) => maturityItem.id === maturity.id
            )
            await CandidateService.removeMaturity(maturityToRemove.id, candidateId)
            this.removeMaturity({ id: maturityToRemove.id, candidateId })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async ADD_SEGMENTATION(newSegmentation: any): Promise<any> {
        try {
            const segmentation = await CandidateService.postSegmentation(newSegmentation)
            this.addSegmentation(segmentation)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_SEGMENTATION(newSegmentation: any): Promise<any> {
        try {
            const segmentation = await CandidateService.patchSegmentation(newSegmentation)
            this.setSegmentation(segmentation)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async REMOVE_SEGMENTATION({ segmentation, candidateId }: { segmentation: any; candidateId: number }) {
        try {
            const segmentationToRemove = this.items[candidateId].segmentations.find(
                (segment) => segment.id === segmentation.id
            )
            await CandidateService.removeSegmentation(segmentationToRemove.id, candidateId)
            this.removeSegmentation({ id: segmentationToRemove.id, candidateId })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async ADD_CITY(newCity: PrioritisedCity): Promise<any> {
        try {
            const city = await CandidateService.addCity(newCity)
            this.addCity(city)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async EDIT_CITY(newCity: PrioritisedCity): Promise<any> {
        try {
            const city = await CandidateService.editCity(newCity)
            this.setCity(city)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    public async REMOVE_CITY({ priority, candidateId }: { priority: number; candidateId: number }) {
        const candidate = this.items[candidateId]
        const citiesToRemove = CandidateModule.findCitiesToRemove(priority, candidate.citiesWanted)
        await citiesToRemove.forEach((city) => {
            CandidateService.removeCity(city.id, candidateId)
            this.removeCity({ id: city.id, candidateId })
        })
    }

    @Action({ rawError: true })
    public async REMOVE_CITY_WITHOUT_PRIORITY({ id, candidateId }: { id: number; candidateId: number }) {
        const cityToRemove = this.items[candidateId].citiesWanted.find((city) => city.city === id)
        if (cityToRemove) {
            await CandidateService.removeCity(cityToRemove.id, candidateId)
            this.removeCity({ id: cityToRemove.id, candidateId })
        }
    }

    private static findCitiesToRemove(priority: number, cities: Array<PrioritisedCity>): Array<PrioritisedCity> {
        // if i remove a city with a priority of one or higher, i want all the cities with a higher priority to be removed
        return cities.filter((city) => city.priority >= priority || !city.priority)
    }

    private static findJobsToRemove(priority: number, jobs: Array<PrioritisedJob>): Array<PrioritisedJob> {
        // if i remove a job with a priority of one or higher, i want all the jobs with a higher priority to be removed
        return jobs.filter((jobObject) => jobObject.priority >= priority)
    }

    // Getters
    @Action({ rawError: true })
    async FETCH_CANDIDATES({ collectionName, params }: { collectionName: string; params: any }): Promise<any> {
        try {
            // Include the tab name in the API request
            const response = await CandidateService.getCandidates({
                ...params,
                interest: params.tabName, // 'tabName' is the new parameter
            })
            const ids: Array<number> = response.results.map((item: Candidate) => item.id)
            const next = response.next === null ? null : extractPage(response.next)
            const previous = response.previous === null ? null : extractPage(response.previous)
            const count = response.count

            this.setItems({ candidates: response.results })
            this.setCollection({ ids, collectionName })
            this.setPagination({
                collectionName,
                pagination: {
                    next,
                    previous,
                    count,
                },
            })
        } catch (error: any) {
            throw error.response || error
        }
    }

    @Action({ rawError: true })
    async FETCH_CANDIDATE_REFERRALS({ collectionName, params }: { collectionName: string; params: any }): Promise<any> {
        try {
            const response = await CandidateService.getCandidateReferrals(params)
            const ids: Array<number> = response.results.map((item: Candidate) => item.id)
            const next = response.next === null ? null : extractPage(response.next)
            const previous = response.previous === null ? null : extractPage(response.previous)
            const count = response.count

            this.setItems({ candidates: response.results })
            this.setCollection({ ids, collectionName })
            this.setPagination({
                collectionName,
                pagination: {
                    next,
                    previous,
                    count,
                },
            })
        } catch (error: any) {
            throw error.response || error
        }
    }

    @Action({ rawError: true })
    async FETCH_CANDIDATE_WITHOUT_REFERRALS({
        collectionName,
        params,
    }: {
        collectionName: string
        params: any
    }): Promise<any> {
        try {
            const response = await CandidateService.getCandidateWithoutReferrals(params)
            const ids: Array<number> = response.results.map((item: Candidate) => item.id)
            const next = response.next === null ? null : extractPage(response.next)
            const previous = response.previous === null ? null : extractPage(response.previous)
            const count = response.count

            this.setItems({ candidates: response.results })
            this.setCollection({ ids, collectionName })
            this.setPagination({
                collectionName,
                pagination: {
                    next,
                    previous,
                    count,
                },
            })
        } catch (error: any) {
            throw error.response || error
        }
    }

    // Getters
    get jobByPriority() {
        return ({ candidateId, priority }: { candidateId: number; priority: number }): PrioritisedJob | undefined => {
            return this.items[candidateId].jobsWanted.find((job) => job.priority === priority)
        }
    }

    get cityByPriority() {
        return ({ candidateId, priority }: { candidateId: number; priority: number }): PrioritisedCity | undefined => {
            return this.items[candidateId].citiesWanted.find((city) => city.priority === priority)
        }
    }
}
