import { Components } from '@/models/Components'
import Practice from '@/models/Practice'
import { client } from '../plugins/axios-plugin'

export default class ComponentService {
    static async fetchComponents(): Promise<any> {
        const response = await client.get(`/components/all-components/`)
        return response.data
    }

    // Here we create an instance of Components
    static async getComponents(): Promise<Components> {
        const data = await this.fetchComponents()
        return new Components(data)
    }

    static async getPractices(): Promise<Practice[]> {
        const response = await client.get(`/users/practices/`)
        return response.data.map((practice: any) => Practice.fromObject(practice))
    }
}
