//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        menus: {
            type: Object,
            required: true,
        },
    },
    methods: {
        color(menu) {
            return menu.disabled ? 'var(--lightBlue)' : 'white'
        },
        closeDrawer() {
            this.$emit('closeDrawer')
        },
    },
}
