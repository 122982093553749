import { Contact } from '../models/Contact'
import { client } from '../plugins/axios-plugin'

export default class JobContactService {
    static async getContacts(id: string): Promise<Contact[]> {
        const contacts = await (await client.get(`/jobs/${id}/contacts/`)).data
        return contacts.map((contact: any) => Contact.fromObject(contact))
    }

    static async changePermission(jobId: number, contactId: number, permission: string): Promise<void> {
        await client.post(`/jobs/${jobId}/permissions/`, { contact: contactId, permission })
    }
}
