import { Prioritised } from '~/models/index'

/* eslint-disable camelcase */
export class PrioritisedJob extends Prioritised {
    public readonly id: number
    public readonly priority: number
    public readonly jobType: number
    public readonly jobName: string
    public readonly experience: number
    public readonly candidate: number
    constructor(id: number, jobType: number, jobName: string, experience: number, priority: number, candidate: number) {
        super(id, priority)
        this.jobType = jobType
        this.jobName = jobName
        this.experience = experience
        this.candidate = candidate
    }

    static toPrioritisedJob(prioritisedJob: PrioritisedJob): PrioritisedJob {
        const jobName = Prioritised.getComponentsName(prioritisedJob.jobType, 'jobsWanted')
        return new PrioritisedJob(
            prioritisedJob.id,
            prioritisedJob.jobType,
            jobName,
            prioritisedJob.experience,
            prioritisedJob.priority,
            prioritisedJob.candidate
        )
    }
}
