//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SearchFilters from '@/components/search/SearchFilters.vue'
import SavedSearchList from '@/components/search/SavedSearchList.vue'

export default {
    components: {
        SearchFilters,
        SavedSearchList,
    },
    computed: {
        searchTerms: {
            get() {
                return this.$store.state.search.searchTerms
            },
            set(value) {
                this.$store.commit('search/setSearchTerms', value)
            },
        },
    },
    mounted() {
        this.doSearch()
    },
    methods: {
        doSearch() {
            this.$store.commit('search/setSearchTerms', this.searchTerms)
            this.$store.dispatch('search/DO_SEARCH')
        },
    },
}
