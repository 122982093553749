export default class Fit {
    public constructor(
        public readonly id: number,
        public autoAlertsActive: boolean = true,
        public average: number = 0,
        public businessModel: number = 0,
        public candidate: any = {},
        public city: number = 0,
        public dateCreated: string = '',
        public dateModified: string = '',
        public educationType: number = 0,
        public hasMatch: boolean = false,
        public isActive: boolean = false,
        public job: any = {},
        public jobType: number = 0,
        public keywords: number = 0,
        public language: number = 0,
        public management: number = 0,
        public masteredSector: number = 0,
        public masteredTechno: number = 0,
        public maturity: number = 0,
        public mbti: number = 0,
        public relatedExperience: number = 0,
        public salary: number = 0,
        public school: number = 0,
        public segmentation: number = 0,
        public softSkills: number = 0,
        public startupXp: number = 0,
        public totalExperience: number = 0,
        public variableSalary: number = 0,
        public wishedSector: number = 0,
        public existingMatchWithSameCompany: any = {}
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(fit: any): Fit {
        return Object.assign(new Fit(fit.id), fit)
    }
}
