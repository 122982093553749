/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import Vue from 'vue'
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { Experience } from '~/models/Experience'
import ExperienceService from '~/services/experience-service'

@Module({
    name: 'experiences',
    stateFactory: true,
    namespaced: true,
})
export default class ExperiencesModule extends VuexModule {
    items: Record<number, Experience> = {}

    collections: Record<string, [number]> = {}

    // Getters
    get ExperiencesForCollection() {
        return (collectionName: string): Array<Experience> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName].map((id) => this.items[id])
        }
    }

    get Collections() {
        return this.collections
    }

    // Mutations
    @Mutation
    setItem(experience: Experience): void {
        Vue.set(this.items, experience.id, Experience.fromObject(experience))
    }

    @Mutation
    removeItem(experienceId: number): void {
        Vue.delete(this.items, experienceId)
    }

    // Mutations
    @Mutation
    setItems(experiences: Array<Experience>): void {
        experiences.forEach((item: Experience) => {
            Vue.set(this.items, item.id, Experience.fromObject(item))
        })
    }

    @Mutation
    setCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        Vue.set(this.collections, collectionName, ids)
    }

    @Mutation
    appendToCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        const allIds = (this.collections[collectionName] || []).concat(ids)
        const uniqIds = [...new Set(allIds)]
        Vue.set(this.collections, collectionName, uniqIds)
    }

    @Mutation
    removeFromCollection({ item, collectionName }: { item: number; collectionName: string }): void {
        const index = this.collections[collectionName].indexOf(item)
        Vue.delete(this.collections[collectionName], index)
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_EXPERIENCES({
        candidateId,
        collectionName,
    }: {
        candidateId: number
        collectionName: string
    }): Promise<any> {
        try {
            const response = await ExperienceService.getExperiences(candidateId)
            const ids: Array<number> = response.results.map((item: Experience) => item.id)
            this.setItems(response.results)
            this.setCollection({ ids, collectionName })
            this.appendToCollection({ ids, collectionName })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async ADD_EXPERIENCE({
        candidateId,
        collectionName,
    }: {
        candidateId: number
        collectionName: string
    }): Promise<any> {
        try {
            const newExperience = await ExperienceService.addExperience(candidateId)
            const ids: Array<number> = [newExperience.id]
            this.setItem(newExperience)
            this.appendToCollection({ ids, collectionName })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async EDIT_EXPERIENCE({
        experience,
        field,
    }: {
        experience: Experience
        field: keyof Experience & string
    }): Promise<any> {
        try {
            const newExperience = await ExperienceService.patchExperience(experience, field)
            this.setItem(newExperience)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async REMOVE_EXPERIENCE({
        id,
        candidateId,
        collectionName,
    }: {
        id: number
        candidateId: number
        collectionName: string
    }) {
        await ExperienceService.removeExperience(id, candidateId)
        this.removeItem(id)
        this.removeFromCollection({ item: id, collectionName })
    }
}
