export class ComponentElement {
    public constructor(
        public readonly id: number,
        public name: string = '',
        public category: string = '',
        public descriptionBody: string = '',
        public descriptionCriteria: string = '',
        public descriptionHeader: string = '',
        public isActive: boolean = true,
        public isTechnical: boolean = false
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(object: any): ComponentElement {
        return Object.assign(new ComponentElement(object.id), object)
    }
}
