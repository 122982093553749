//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import debounce from 'lodash.debounce'
import ResultCard from './ResultCard'
import SearchService from '~/services/search-service'

export default {
    components: {
        ResultCard,
    },
    props: {
        expand: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            results: null,
            openList: false,
            // to make unit tests work i need to put mobile as a data
            mobile: this.$vuetify.breakpoint.xs,
            searchOption: 'Candidates',
        }
    },
    computed: {
        isMobile() {
            return this.mobile
        },
        isIconVisible() {
            return this.isMobile && !this.expand
        },
        isTextFieldVisible() {
            return !this.isMobile || this.expand
        },
        items() {
            return this.results?.map((result) => {
                // Handle link to company profile when it is there
                let route = ''
                if (this.searchOption === 'Candidates') {
                    route = { path: `/candidate/${result.candidateId}/general`, query: { from: 'search' } }
                } else {
                    route = `/company/${result.id}/general`
                }
                return { ...result, route }
            })
        },
    },
    mounted() {
        this.debouncedSearch = debounce(this.search, 300)
    },
    methods: {
        handleKeyUp(event) {
            this.debouncedSearch(event)
        },
        async search(event) {
            this.openList = true
            if (this.searchOption === 'Candidates') {
                this.results = await SearchService.getCandidates(event.target.value)
            } else {
                this.results = await SearchService.getCompanies(event.target.value)
            }
        },
        clickOutside() {
            this.openList = false
        },
    },
}
