//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        phoneModel: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            invalidPhoneNumber: false,
            phoneValue: '',
            isRequired: false,
        }
    },
    computed: {
        showInvalidPhoneNumberError() {
            return this.invalidPhoneNumber && !this.isRequired
        },
    },
    mounted() {
        this.phoneValue = this.phoneModel
    },
    methods: {
        validationPhoneNumber(phoneNumber, phoneObject) {
            this.invalidPhoneNumber = false
            this.isRequired = false

            if (phoneNumber.length <= 0 || !phoneObject.isValid) {
                this.invalidPhoneNumber = true
            } else {
                this.$emit('inputPhoneNumber', phoneNumber)
            }

            if (phoneNumber.length === 0) {
                this.invalidPhoneNumber = true
                this.isRequired = true
            }
        },
    },
}
