import Vue from 'vue'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import Fit from '~/models/Fit'
import FitService from '~/services/fit-service'

type pagination = {
    count: number
    next: number | null
    previous: number | null
}

const extractPage = (url: string): number | null => {
    const urlObject = new URL(url)
    const query = new URLSearchParams(urlObject.search)
    const page = query.get('page')
    if (page === null) {
        return null
    }
    return parseInt(page)
}

@Module({
    name: 'fits',
    stateFactory: true,
    namespaced: true,
})
export default class FitsModule extends VuexModule {
    items: Record<number, Fit> = {}

    collections: Record<string, [number]> = {}

    paginations: Record<string, pagination> = {}

    currentFitIdToDisplay: number | null = null

    currentSelectedFitIndex = 0

    get FitById() {
        return (id: number): Fit => {
            return this.items[id]
        }
    }

    get FitsByIds() {
        return (ids: Array<number>): Array<Fit> => {
            // I added filter here to avoid having undefined return in the array
            return ids.map((id) => this.items[id]).filter((item) => item)
        }
    }

    get Collection() {
        return (collectionName: string): Array<number> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName]
        }
    }

    get FitsForCollection() {
        return (collectionName: string): Array<Fit> => {
            if (!this.collections[collectionName]) {
                return []
            }
            return this.collections[collectionName].map((id) => this.items[id])
        }
    }

    get NextPageForCollection() {
        return (collectionName: string): number | null => {
            if (!this.paginations[collectionName]) {
                return null
            }
            return this.paginations[collectionName].next
        }
    }

    get HasMoreForCollection() {
        return (collectionName: string): boolean => {
            return !!this.paginations[collectionName]?.next
        }
    }

    get CountForCollection() {
        return (collectionName: string): number | null => {
            if (!this.paginations[collectionName]) {
                return null
            }
            return this.paginations[collectionName].count
        }
    }

    get DateForCollection() {
        return (collectionName: string): string | null => {
            if (!this.collections[collectionName] || !this.collections[collectionName].length) {
                return null
            }
            const firstItemInCollection = this.collections[collectionName][0]
            return this.items[firstItemInCollection].dateModified
        }
    }

    get ShouldLoadMoreForCollection() {
        return (collectionName: string): boolean | null => {
            if (!this.collections[collectionName]) {
                return null
            }
            return !!this.paginations[collectionName]?.next && this.collections[collectionName].length <= 0
        }
    }

    get CurrentFitToDisplay() {
        if (this.currentFitIdToDisplay) {
            return this.items[this.currentFitIdToDisplay]
        }
        return {}
    }

    get CurrentSelectedFitIndex() {
        return this.currentSelectedFitIndex
    }

    @Mutation
    setItems({ fits }: { fits: Array<Fit> }) {
        fits.forEach((item: Fit) => {
            Vue.set(this.items, item.id, Fit.fromObject(item))
        })
    }

    @Mutation
    setCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        Vue.set(this.collections, collectionName, ids)
    }

    @Mutation
    appendToCollection({ ids, collectionName }: { ids: Array<number>; collectionName: string }) {
        const allIds = (this.collections[collectionName] || []).concat(ids)
        const uniqIds = [...new Set(allIds)]
        Vue.set(this.collections, collectionName, uniqIds)
    }

    @Mutation
    setPagination({ pagination, collectionName }: { pagination: pagination; collectionName: string }): void {
        Vue.set(this.paginations, collectionName, pagination)
    }

    @Mutation
    setCollectionCount({ collectionName, length }: { collectionName: string; length: number }): void {
        this.paginations[collectionName].count -= length
    }

    @Mutation
    removeFromCollection({ item, collectionName }: { item: number; collectionName: string }): void {
        const index = this.collections[collectionName].indexOf(item)
        Vue.delete(this.collections[collectionName], index)
    }

    @Mutation
    toggleFromCollection({ id, collectionName }: { id: number; collectionName: string }) {
        if (this.collections[collectionName]) {
            const index = this.collections[collectionName].indexOf(id)
            if (index >= 0) {
                Vue.delete(this.collections[collectionName], index)
            } else {
                this.collections[collectionName].push(id)
            }
        } else {
            Vue.set(this.collections, collectionName, [id])
        }
    }

    @Mutation
    deleteCollection(collectionName: string) {
        Vue.delete(this.collections, collectionName)
    }

    @Mutation
    setCurrentFitIdToDisplay(fitId: number) {
        this.currentFitIdToDisplay = fitId
    }

    @Mutation
    setCurrentSelectedFitIndex(index: number) {
        this.currentSelectedFitIndex = index
    }

    @Action({ rawError: true })
    ADD_FIT_TO_STORE(fit: Fit) {
        this.setItems({ fits: [fit] })
    }

    @Action({ rawError: true })
    async FETCH_FITS({
        id,
        collectionName,
        page,
        target,
        statuses,
    }: {
        id: number
        collectionName: string
        page: number
        target: string
        statuses: string
    }): Promise<any> {
        try {
            const response = await FitService.getFits(id, page, target, statuses)
            const ids: Array<number> = response.results.map((item: Fit) => item.id)
            const next = response.next === null ? null : extractPage(response.next)
            const previous = response.previous === null ? null : extractPage(response.previous)
            const count = response.count

            this.setItems({ fits: response.results })

            // To use default candidate status filter, append to collection only if user is loading more fits
            // set collection otherwise
            if (page > 1) {
                this.appendToCollection({ ids, collectionName })
            } else {
                this.setCollection({ ids, collectionName })
            }
            this.setPagination({
                collectionName,
                pagination: {
                    next,
                    previous,
                    count,
                },
            })
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async ACCEPT_FITS({
        id,
        fitIds,
        collectionName,
        target,
        status,
    }: {
        id: number
        fitIds: Array<number>
        collectionName: string
        target: string
        status: string
    }) {
        const matches = await FitService.acceptFits(id, fitIds, target, status)
        fitIds.forEach((fit) => this.removeFromCollection({ item: fit, collectionName }))
        this.setCollectionCount({ collectionName, length: fitIds.length })
        return matches
    }

    @Action({ rawError: true })
    async REFUSE_FITS({
        id,
        fitIds,
        collectionName,
        target,
    }: {
        id: number
        fitIds: Array<number>
        collectionName: string
        target: string
    }) {
        await FitService.refuseFits(id, fitIds, target)
        if (collectionName) {
            fitIds.forEach((fit) => this.removeFromCollection({ item: fit, collectionName }))
            this.setCollectionCount({ collectionName, length: fitIds.length })
        }
    }

    @Action({ rawError: true })
    async RESET_FITS({ id, collectionName, target }: { id: number; collectionName: string; target: string }) {
        const response = await FitService.resetFits(id, target)
        const ids: Array<number> = response.results.map((item: Fit) => item.id)
        const next = response.next === null ? null : extractPage(response.next)
        const previous = response.previous === null ? null : extractPage(response.previous)
        const count = response.count

        this.setItems({ fits: response.results })
        this.setCollection({ ids, collectionName })
        this.setPagination({
            collectionName,
            pagination: {
                next,
                previous,
                count,
            },
        })
    }
}
