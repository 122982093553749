//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SavedSearch from '@/components/search/SavedSearch.vue'
export default {
    components: {
        SavedSearch,
    },
    data() {
        return {
            dialog: false,
            searchName: '',
        }
    },
    computed: {
        searches() {
            return this.$store.getters['search/GetSearches']()
        },
    },
    methods: {
        saveSearch() {
            this.dialog = false
            this.$store.dispatch('search/SAVE_SEARCH', this.searchName)
        },
    },
}
