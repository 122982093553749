//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
    props: {
        active: {
            type: Boolean,
            required: false,
            default: false,
        },
        search: {
            type: Object,
            required: true,
        },
    },
    methods: {
        getSubtitle() {
            let terms = 'No terms'
            if (this.search.terms) {
                terms = `Terms: ${this.search.terms}`
            }
            let filters = 'No filters'
            if (this.search.filters) {
                filters = `Filters: ${Object.keys(this.search.filters).join(', ')}`
            }
            return `${terms}, ${filters}`
        },
        onDelete() {
            this.$store.dispatch('search/DELETE_SEARCH', this.search.id)
        },
        onSelect() {
            this.$store.commit('search/setActiveSearch', {
                id: this.search.id,
                terms: this.search.terms,
                filters: this.search.filters,
            })
            this.$store.dispatch('search/DO_SEARCH')
        },
    },
}
