/* eslint-disable import/no-mutable-exports */
import { Store } from 'vuex'
import { getModule } from 'vuex-module-decorators'
import candidates from '../store/candidates'
import components from '../store/components'
import contacts from '../store/contacts'
import listing from '../store/listing'
import toasters from '../store/toasters'
import listingFilters from '../store/listingFilters'
import experiences from '../store/experiences'
import companies from '../store/companies'
import companyContacts from '../store/companyContacts'
import jobContacts from '../store/jobContacts'
import jobs from '../store/jobs'
import fits from '../store/fits'
import matches from '../store/matches'
import teamUsers from '../store/teamUsers'
import referrals from '../store/referrals'
import search from '../store/search'
import ui from '../store/ui'

let listingStore: listing
let componentsStore: components
let candidatesStore: candidates
let contactsStore: contacts
let toastersStore: toasters
let listingFiltersStore: listingFilters
let experiencesStore: experiences
let companiesStore: companies
let companyContactsStore: companyContacts
let jobContactsStore: jobContacts
let jobsStore: jobs
let fitsStore: fits
let matchesStore: matches
let teamUsersStore: teamUsers
let referralsStore: referrals
let searchStore: search
let uiStore: ui

function initialiseStores(store: Store<any>): void {
    listingStore = getModule(listing, store)
    candidatesStore = getModule(candidates, store)
    componentsStore = getModule(components, store)
    contactsStore = getModule(contacts, store)
    toastersStore = getModule(toasters, store)
    listingFiltersStore = getModule(listingFilters, store)
    experiencesStore = getModule(experiences, store)
    companiesStore = getModule(companies, store)
    companyContactsStore = getModule(companyContacts, store)
    jobContactsStore = getModule(jobContacts, store)
    jobsStore = getModule(jobs, store)
    fitsStore = getModule(fits, store)
    matchesStore = getModule(matches, store)
    teamUsersStore = getModule(teamUsers, store)
    referralsStore = getModule(referrals, store)
    searchStore = getModule(search, store)
    uiStore = getModule(ui, store)
}

export {
    initialiseStores,
    listingStore,
    componentsStore,
    candidatesStore,
    contactsStore,
    toastersStore,
    listingFiltersStore,
    experiencesStore,
    companiesStore,
    companyContactsStore,
    jobContactsStore,
    jobsStore,
    fitsStore,
    matchesStore,
    teamUsersStore,
    referralsStore,
    searchStore,
    uiStore,
}
