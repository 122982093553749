export class Experience {
    public constructor(
        public readonly id: number,
        public candidate: number = 1,
        public businessModel: number = 1,
        public size: number = 1,
        public employmentContract: number = 1,
        public period: number = 2,
        public jobName: string = 'CTO',
        public companyName: string = 'Ignition',
        public notes: string = '',
        public reasonWhy: string = '',
        public achievements: string = '',
        public managees: number = 0
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(experience: any): Experience {
        return Object.assign(new Experience(experience.id), experience)
    }
}
