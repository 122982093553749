import Vue from 'vue'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import Company from '@/models/Company'
import CompanyService from '~/services/company-service'

@Module({
    name: 'companies',
    stateFactory: true,
    namespaced: true,
})
export default class CompaniesModule extends VuexModule {
    items: Record<number, Company> = {}

    // Getters
    get Companies(): Array<Company> {
        return Object.values(this.items)
    }

    // Getters
    get CompanyById() {
        return (id: number): Company => {
            return this.items[id]
        }
    }

    // Mutations
    @Mutation
    fetchCompanySuccess(item: Company): void {
        Vue.set(this.items, item.id, item)
    }

    @Mutation
    patchCompanySuccess(item: Company): void {
        Vue.set(this.items, item.id, item)
    }

    @Action({ rawError: true })
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    async FETCH_COMPANY(companyId: number): Promise<any> {
        try {
            const company = await CompanyService.getCompany(companyId)
            this.fetchCompanySuccess(company)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async PATCH_COMPANY(params: { id: number; field: string; value: any }): Promise<Company> {
        try {
            const company = await CompanyService.patchCompany(params.id, params.field, params.value)
            this.patchCompanySuccess(company)
            return company
        } catch (error: any) {
            throw error.response
        }
    }
}
