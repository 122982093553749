/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'
import Vue from 'vue'
import ListingService from '~/services/listing-service'

@Module({
    name: 'listing',
    stateFactory: true,
    namespaced: true,
})
export default class ReferralModule extends VuexModule {
    // State
    listings: any = {} as any

    // Mutations
    @Mutation
    addList(patchingObject: any): void {
        if (this.listings[patchingObject.name]) {
            if (!this.listings[patchingObject.name][patchingObject.tab]) {
                Vue.set(this.listings[patchingObject.name], patchingObject.tab, {
                    pages: {
                        [patchingObject.page]: patchingObject.data?.results,
                    },
                    count: patchingObject.data.count,
                })
            }
        } else {
            Vue.set(this.listings, patchingObject.name, {})
            Vue.set(this.listings[patchingObject.name], patchingObject.tab, {
                pages: { [patchingObject.page]: patchingObject.data?.results },
                count: patchingObject.data.count,
            })
        }
    }

    @Mutation
    addItems(patchingObject: any): void {
        Vue.set(this.listings[patchingObject.name], patchingObject.tab, {
            pages: {
                ...this.listings[patchingObject.name][patchingObject.tab].pages,
                [patchingObject.page]: patchingObject.data?.results,
            },
            count: patchingObject.data.count,
        })
    }

    @Mutation
    updateEntity(patchingObject: any): void {
        Vue.set(this.listings, patchingObject.name, {})
        Vue.set(this.listings[patchingObject.name], patchingObject.tab, {
            pages: { [patchingObject.page]: patchingObject.data?.results },
            count: patchingObject.data.count,
        })
    }

    @Mutation
    removePage(patchingObject: any): void {
        Vue.set(this.listings, patchingObject.name, undefined)
    }

    @Mutation
    removePageItems(patchingObject: any): void {
        Vue.set(this.listings[patchingObject.name], patchingObject.tab, {
            count: 0,
            pages: {},
        })
    }

    @Mutation
    removeSpecificItemPage(patchingObject: any): void {
        Vue.set(
            this.listings[patchingObject.name][patchingObject.tab].pages,
            patchingObject.page,
            this.listings[patchingObject.name][patchingObject.tab].pages[patchingObject.page].filter(
                (item: any) => item.id !== patchingObject.id
            )
        )
    }

    // Actions
    @Action({ rawError: true })
    async LOAD_DATA(data: any): Promise<void> {
        try {
            const response = await ListingService.getListing(
                data.url,
                data.page,
                data.order,
                data.extraParam,
                data.filters
            )
            const patchingObject = {
                url: data.url,
                name: data.name,
                tab: data.tab,
                page: data.page,
                data: response,
            }
            this.addList(patchingObject)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    ADD_LIST(data: any): void {
        this.addList(data)
    }

    @Action
    async ADD_ITEMS(data: any): Promise<void> {
        try {
            const response = await ListingService.getListing(
                data.url,
                data.page,
                data.order,
                data.extraParam,
                data.filters
            )
            const patchingObject = {
                url: data.url,
                name: data.name,
                tab: data.tab,
                page: data.page,
                data: response,
                filters: data.filters,
            }
            this.addItems(patchingObject)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    async UPDATE_ENTITY(data: any): Promise<void> {
        try {
            const response = await ListingService.getListing(
                data.url,
                data.page,
                data.order,
                data.extraParam,
                data.filters
            )
            const patchingObject = {
                url: data.url,
                name: data.name,
                tab: data.tab,
                page: data.page,
                data: response,
            }
            this.updateEntity(patchingObject)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action
    REMOVE_PAGE(data: any): void {
        this.removePage(data)
    }

    @Action
    REMOVE_PAGE_ITEMS(data: any): void {
        this.removePageItems(data)
    }

    @Action({ rawError: true })
    REMOVE_SPECIFIC_PAGE_ITEM(data: any): void {
        this.removeSpecificItemPage(data)
    }

    // Getters
    get getPage() {
        return (tab: any, item: any, page: any): Array<any> => {
            if (!this.listings[tab] || !this.listings[tab][item] || !this.listings[tab][item].pages) return []
            return this.listings[tab][item].pages[page]
        }
    }

    get getList() {
        return (tab: any) => this.listings[tab] || []
    }
}
