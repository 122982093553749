export default class Company {
    public constructor(
        public readonly id: number = 1,
        public hubspotId: string = '',
        public name: string = '',
        public currentStatus: number = 1,
        public currentStatusString: string = '',
        public lastStatusUpdate: number = 1,
        public industry: string = '',
        public nbEmployees: number = 1,
        public creationYear: number = 1,
        public annualRevenue: string = '',
        public website: string = '',
        public description: string = '',
        public culture: string = '',
        public pitch: string = '',
        public associatedMm: string = '',
        public logoUrl: string = ''
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(company: any): Company {
        return Object.assign(new Company(company.id), company)
    }
}
