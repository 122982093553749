/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { client } from '../plugins/axios-plugin'

export default class ListingService {
    static async fetchListing(url: string, page: number, order: string, extraParam: any, filters: any): Promise<any> {
        const data: any = {}
        if (page) {
            data.page = page
        }
        if (extraParam?.field) {
            data[extraParam.field] = extraParam.data
        }
        if (order) {
            data.ordering = order
        }
        if (filters) {
            // we get an object with keys as the field of the filters and the value as the filter value ('France', or an id)
            for (const [field, value] of Object.entries(filters)) {
                data[field] = value
            }
        }
        const searchParams = new URLSearchParams(data).toString().replace(/%2C/g, ',')

        const response = await client.get(`${url}?${searchParams}`)
        return response.data
    }

    // Here we create an instance of Components
    static async getListing(url: string, page: number, order: string, extraParam: any, filters: any): Promise<any> {
        return await this.fetchListing(url, page, order, extraParam, filters)
    }

    static async getAllJobsListing(data: any): Promise<any> {
        const searchParams = new URLSearchParams(data).toString().replace(/%2C/g, ',')
        const response = await client.get(`jobs/all-jobs?${searchParams}`)
        return response.data
    }

    static async getMyJobsListing(data: any): Promise<any> {
        const searchParams = new URLSearchParams(data).toString().replace(/%2C/g, ',')
        const response = await client.get(`jobs/my-jobs?${searchParams}`)
        return response.data
    }
}
