/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { client } from '../plugins/axios-plugin'

export default class FitService {
    static async getFits(id: number, nextPage: number, target: string, statuses: string): Promise<any> {
        const statusFilter = statuses ? `&statuses=${statuses}` : ''
        const response = await client.get(`/${target}/${id}/fits/?page=${nextPage}${statusFilter}`)
        return response.data
    }

    static async getCandidateFits(id: number, nextPage: number): Promise<any> {
        const response = await client.get(`/candidates/${id}/fits/?page=${nextPage}`)
        return response.data
    }

    static async acceptFits(id: number, fits: Array<number>, target: string, status: string): Promise<any> {
        const matchStatus = status.toLowerCase().replace(' ', '_')
        const response = await client.post(`/${target}/${id}/fits/accept_fits/?status=${matchStatus}`, { fits })
        return response.data
    }

    static async refuseFits(id: number, fits: Array<number>, target: string): Promise<any> {
        const response = await client.patch(`/${target}/${id}/fits/refuse_fits/`, { fits })
        return response.data
    }

    static async resetFits(id: number, target: string): Promise<any> {
        const response = await client.patch(`/${target}/${id}/fits/reset_fits/`)
        return response.data
    }

    static async getCount(id: number, target: string): Promise<any> {
        const response = await client.get(`/${target}/${id}/fits/count/`)
        return response.data
    }
}
