//
//
//
//
//
//

export default {
    props: {
        title: {
            type: String,
            required: true,
        },
        filtersName: {
            type: String,
            required: true,
        },
    },
    computed: {
        value: {
            get() {
                // Get the filter object and then extract its value
                const filter = this.$store.getters['search/GetFilters'](this.filtersName)
                return filter ? filter.value : false
            },
            set(value) {
                // Set the filter as an object with a value property
                this.$store.commit('search/setFilters', {
                    name: this.filtersName,
                    filter: { value },
                })
            },
        },
    },
}
