// this plugin is the only way fo the unit test

import pdf from 'vue-pdf'
import Vue from 'vue'
import DatetimePicker from 'vuetify-datetime-picker'
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib/vue-tel-input-vuetify.vue'
// (Optional) import 'vuetify-datetime-picker/src/stylus/main.styl'

Vue.use(DatetimePicker)
Vue.component('pdf', pdf)
Vue.component('vue-tel-input-vuetify', VueTelInputVuetify)
