//
//
//
//
//
//
//

import AppBarMobile from './AppBarMobile.vue'
import AppBarDesktop from './AppBarDesktop.vue'

export default {
    components: {
        AppBarMobile,
        AppBarDesktop,
    },
    data() {
        return {
            menus: {
                candidates: {
                    title: 'Candidates',
                    items: [
                        {
                            title: 'Applications',
                            to: '/listing/candidate-applications/new',
                            prefillPracticeFilter: true,
                        },
                        {
                            title: 'Ready',
                            to: '/listing/candidate-admitted',
                            prefillCoachFilter: true,
                        },
                        {
                            title: 'Referrals',
                            to: '/listing/candidate-referrals/ref-created',
                            prefillCoachFilter: true,
                        },
                        {
                            divider: true,
                        },
                        // TODO: delete when not more needed from teach team
                        /*                        {
                            title: 'Candidate DB',
                            to: '/listing/candidate-db',
                        }, */
                        {
                            title: 'Candidate Search',
                            to: '/listing/candidate-search',
                        },
                    ],
                },
                jobs: {
                    title: 'Jobs',
                    to: '/listing/jobs',
                    prefillRecruiterFilter: true,
                },
                matches: {
                    title: 'Matching',
                    items: [
                        {
                            title: 'Bot recommandations',
                            to: '/home',
                        },
                        {
                            title: 'Matches listing',
                            to: '/listing/matches/internal-check',
                            prefillOwnerFilter: true,
                        },
                    ],
                },
                ai_tools: {
                    title: 'AI Tools (bêta)',
                    to: '/ai_tools/aiTools',
                },
            },
        }
    },
    computed: {
        isMobile() {
            return this.$vuetify.breakpoint.xs
        },
    },
}
