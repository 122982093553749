//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import CardContent from './CardContent'

export default {
    components: {
        CardContent,
    },
    props: {
        items: {
            type: Array,
            required: true,
        },
        itemType: {
            type: String,
            required: false,
            default: 'Candidates',
        },
    },
    computed: {
        itemsNotEmpty() {
            return this.items?.length > 0
        },
    },
}
