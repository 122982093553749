import Vue from 'vue'
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { Module, VuexModule, Action, Mutation } from 'vuex-module-decorators'

import { Contact } from '~/models/Contact'
import JobContactService from '~/services/job-contact-service'

@Module({
    name: 'jobContacts',
    stateFactory: true,
    namespaced: true,
})
export default class JobContactsModule extends VuexModule {
    /* Items is a dictionnary with id as key and Contact as value
        Example : {
            1: Contact,
            23: Contact
        }
    */
    items: Record<number, Contact> = {}

    /*
        Collections is a dictionnary with string as key and an array of ids as value
        The numbers in those values are pointing to the keys store in the items
        Example : {
            contactsForJob1: [number, number, number],
        }
    */
    collections: Record<string, [number]> = {}

    // Getters
    get ContactsByIds() {
        return (ids: Array<number>): Array<Contact> => {
            // I added filter here to avoid having undefined return in the array
            return ids.map((id) => this.items[id]).filter((item) => item)
        }
    }

    get ContactsForJobId() {
        return (jobId: string): Array<Contact> => {
            if (!this.collections[`contactsForJob${jobId}`]) {
                return []
            }
            // I added filter here to avoid having undefined return in the array
            return this.collections[`contactsForJob${jobId}`].map((id) => this.items[id]).filter((item) => item)
        }
    }

    // Mutations
    @Mutation
    fetchContactsSuccess(params: { contacts: Array<Contact>; jobId: string }): void {
        // We store contacts in this.items
        params.contacts.map((item) => {
            Vue.set(this.items, item.id, item)
        })
        // We create the field contactsForJobX in this.collections with the ids of the returned contacts
        if (params.jobId) {
            const contactIds: Array<number> = params.contacts.map((item) => item.id)
            Vue.set(this.collections, `contactsForJob${params.jobId}`, contactIds)
        }
    }

    @Mutation
    changePermissionSuccess(patchingObject: any): void {
        Vue.set(
            this.items,
            patchingObject.contactId,
            Contact.fromObject({
                ...this.items[patchingObject.contactId],
                permission: patchingObject.permission,
            })
        )
    }

    @Action({ rawError: true })
    async CHANGE_PERMISSION(patchingObject: any): Promise<any> {
        try {
            await JobContactService.changePermission(
                patchingObject.jobId,
                patchingObject.contactId,
                patchingObject.permission
            )
            this.changePermissionSuccess(patchingObject)
        } catch (error: any) {
            throw error.response
        }
    }

    @Action({ rawError: true })
    async FETCH_CONTACTS(jobId: string): Promise<any> {
        try {
            const contacts = await JobContactService.getContacts(jobId)
            this.fetchContactsSuccess({ contacts, jobId })
        } catch (error: any) {
            throw error.response
        }
    }
}
