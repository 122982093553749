export class Referral {
    public constructor(
        public readonly id: number = 1,
        public readonly candidate: number = 1,
        public firstName: string = '',
        public lastName: string = '',
        public position: string = '',
        public company: string = '',
        public gender: string = '',
        public email: string = '',
        public phoneNumber: string = '',
        public currentStringStatus: string = '',
        public currentStatus: number = 1,
        public answerSituations: string = '',
        public anwserAdjectives: string = '',
        public answerStanding: string = '',
        public answerAdvise: string = '',
        public actions: any = {},
        public daysSinceFirstContact: number | null = null,
        public daysUntilNextContact: number | null = null,
        public candidateFullName: string = '',
        public isRefReviewed: boolean = false
    ) {}

    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static fromObject(referral: any): Referral {
        return Object.assign(new Referral(referral.id), referral)
    }
}
