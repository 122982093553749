//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import NavigationList from '../lists/NavigationList.vue'
import Avatar from '../Avatar.vue'
import SearchBar from '../search-bar/SearchBar.vue'
import UserSettings from '../UserSettings.vue'

export default {
    components: {
        NavigationList,
        SearchBar,
        Avatar,
        UserSettings,
    },
    props: {
        menus: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            clipped: false,
        }
    },
    computed: {
        pictureUrl() {
            return this.$auth?.user?.picture_url
        },
        teamUsers() {
            return this.$store.getters['teamUsers/AllTeamUsers']()
        },
        teamCoaches() {
            return this.$store.getters['teamUsers/AllCoaches']()
        },
    },
    methods: {
        getItemsWithLinks(items) {
            const newArray = items.map((item) => {
                return { ...item, to: this.getMenuLink(item) }
            })
            return newArray
        },
        color(menu) {
            return menu.disabled ? 'var(--lightBlue)' : 'white'
        },
        itemsPresent(menu) {
            if (menu?.items) {
                return menu.items.length > 0
            }
            return false
        },
        // This method is used in order to redirect the user to the right page with the correct queries in the url
        getMenuLink(menu) {
            const currentUserPractice = this.$auth.user.practice
            const currentUserId = this.$auth.user.id
            const teamUsersIds = this.teamUsers.map((user) => user.id)
            const isInTeamUsersArray = teamUsersIds.includes(currentUserId)
            let url = menu.to
            if (menu.prefillOwnerFilter) {
                // We want to add the current user to initialize the owner filter in the match listing page
                url = isInTeamUsersArray
                    ? `${menu.to}?owners=${currentUserId}&ordering=creator_type,-last_status_update`
                    : `${menu.to}?ordering=creator_type,-last_status_update`
            } else if (menu.prefillCoachFilter) {
                // If the current user is a coach.
                // We want to add the current user to initialize the coaches filter in the admitted candidates listing page
                url = isInTeamUsersArray ? `${menu.to}?coaches=${currentUserId}` : menu.to
            } else if (menu.prefillRecruiterFilter) {
                // If the current user is a recruiter
                url = isInTeamUsersArray ? `${menu.to}?recruiters=${currentUserId}` : menu.to
            } else if (menu.prefillPracticeFilter) {
                url = currentUserPractice ? `${menu.to}?practices=${currentUserPractice}` : menu.to
            }
            return url
        },
    },
}
